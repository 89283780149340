import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Button, Dialog, DialogTitle, DialogContent, IconButton, TablePagination, Typography, CircularProgress, Box
} from '@mui/material';
import MainLayout from '../../layout/mainLayout';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ModalComponent from '../../components/atoms/ModalComponent';
import { message, Modal } from 'antd';
import { duration } from 'moment';
import { getTransactions, getSessions } from "../../redux/apis/chargers"
import { useSelector } from 'react-redux';
import Pusher from 'pusher-js';
import { fetchEvseStationsAPI } from '../../redux/apis/evseStations';


const SessionTable = () => {
    const [openSessionDetails, setOpenSessionDetails] = useState(false);
    const [openMeterValues, setOpenMeterValues] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const [selectedMeterValues, setSelectedMeterValues] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openTransactionDetails, setOpenTransactionDetails] = useState(false);
    const [sessionsData, setSessionsData] = useState([]);
    const [sessionTechData, setSessionTechData] = useState([]);
    const [technicalDetailsSession, setTechnialDetailsSession] = useState(null);
    const selectedFilter = useSelector(state => state.filter.selectedFilter);
    const [loading, setLoading] = useState(false);
    const [totalSessions, setTotalSessions] = useState(0);
    const [stations, setStations] = useState([]);
    const [techloading, setTechLoading] = useState(false);

    //fetch evse Stations
    useEffect(() => {
        const fetchEvseStations = async () => {
            try {
                const response = await fetchEvseStationsAPI({
                    limit: 10000
                });
                setStations(response.list);
            }
            catch (error) {
                console.log(error);
                message.error(error.response.data.message);
            }
        }
        fetchEvseStations();
    }, []);

    function formatDuration(durationInSeconds) {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
      
        // Format with leading zeros if needed
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
      
        return `${formattedHours} hr : ${formattedMinutes} min`;
      }

    //update on event
    useEffect(() => {
        // Initialize Pusher
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
            cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
        });

        // Subscribe to the channel
        const channel = pusher.subscribe('pusher-node-app');

        // Bind to the event
        channel.bind('transaction-created', (data) => {
            fetchTransactions();
        });

        channel.bind('transaction-updated', (data) => {
            fetchTransactions();
        });

    }, []);


    const fetchTransactions = async () => {
        setLoading(true);
        try {
            const response = await getTransactions({
                filter: (selectedFilter ? `{"chargeBoxId":"${selectedFilter.value}"}` : ""),
                page: page + 1,
                limit: rowsPerPage,
                sort:"createdAt:desc"
            });
            setTotalSessions(response.totalCount);
            setSessionsData(response.list);
        }
        catch (error) {
            console.log(error);
            message.error("Failed To Fetch Transactions");
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchTransactions();
    }, [selectedFilter, page, rowsPerPage]);

    const fetchSessions = async () => {
        setTechLoading(true);
        try {
            if (technicalDetailsSession != null) {
                const response = await getSessions(technicalDetailsSession?.transactionUuid);
                setSessionTechData(response.list);
            }
        }
        catch (error) {
            console.log(error);
            message.error(error.response.data.message);
        }
        finally {
            setTechLoading(false);
        }
    }

    useEffect(() => {
        fetchSessions();
    }, [technicalDetailsSession]);

    const handleTransactionDetailsClick = (sessionData) => {
        setSelectedSession(sessionData);
        setOpenTransactionDetails(true);
    };

    const handleCloseTransactionDetails = () => {
        setOpenTransactionDetails(false);
    };
    // Handle pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    // Open Session Details popup
    const handleDetailsClick = (session) => {
        setTechnialDetailsSession(session);
        setOpenSessionDetails(true);

    };

    // Open Meter Values popup
    const handleMeterValuesClick = (meterValues) => {
        setSelectedMeterValues(meterValues);
        setOpenMeterValues(true);
    };

    const handleCloseSessionDetails = () => {
        setOpenSessionDetails(false);
    };

    const handleCloseMeterValues = () => {
        setOpenMeterValues(false);
    };

    const handleDownloadInvoice = (invoiceUrl) => {
        // Trigger file download
        const link = document.createElement("a");
        link.href = invoiceUrl;
        link.download = "invoice.pdf"; // Customize the filename if needed
        link.click();
    };

    return (
        <MainLayout>
            <div>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {/* Main Table */}
                        <TableContainer
                            sx={{
                                maxHeight: "60vh",
                                overflow: "auto",
                                borderRadius: "8px 8px 0 0",
                                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                                border: "1px solid #e0e0e0",
                                backgroundColor: "#ffffff",
                            }}
                        >
                            <Table>
                                <TableHead
                                    sx={{
                                        position: "sticky",
                                        top: 0,
                                        zIndex: 99,
                                        "& th": {
                                            border: "1px solid #e0e0e0",
                                            background: "#F5F3F3",
                                            fontWeight: "500",
                                            color: "#333",
                                        },
                                        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                                    }}>

                                    <TableRow>
                                        <TableCell align='center'>Sr. No</TableCell>
                                        <TableCell align='center'>Session ID</TableCell>
                                        <TableCell align='center'>ChargeBox Id</TableCell>
                                        <TableCell align='center'>EVSE Station</TableCell>
                                        <TableCell align='center'>Date</TableCell>
                                        <TableCell align='center'>Customer Name</TableCell>
                                        <TableCell align='center'>Start Time</TableCell>
                                        <TableCell align='center'>End Time</TableCell>
                                        <TableCell align='center'>Total Amount</TableCell>
                                        <TableCell align='center'>Transaction ID</TableCell>
                                        <TableCell align='center'>Session Invoices</TableCell>
                                        <TableCell align='center'>Technical Details</TableCell>
                                        <TableCell align='center'>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody
                                    sx={{
                                        "& td": {
                                            border: "1px solid #e0e0e0",
                                            color: "#555",
                                        },
                                        "& tr:nth-of-type(odd)": {
                                            backgroundColor: "#fafafa",
                                        },

                                    }}>
                                    {sessionsData
                                        .map((session, index) => (
                                            <TableRow key={session.transactionUuid}>
                                                <TableCell align='center'>{page * rowsPerPage + index + 1}</TableCell>
                                                <TableCell align='left'>{session?.transactionUuid}</TableCell>
                                                <TableCell align='center'>{session?.chargeBoxId}</TableCell>
                                                <TableCell align='center'>{stations.filter(station => station.id == session?.evseStationId)[0]?.name}</TableCell>
                                                <TableCell align='center'>{new Date(session?.createdAtLocal).toLocaleDateString()}</TableCell>
                                                <TableCell align='center'>{session?.customerName || '--'}</TableCell>
                                                <TableCell align='center'>{new Date(session?.startTimeLocal).toLocaleTimeString()}</TableCell>
                                                <TableCell align='center'>{session.paymentStatus === "pending" ? "--" : new Date(session?.endTimeLocal).toLocaleTimeString()}</TableCell>
                                                <TableCell align='center'>{session.paymentStatus === "pending" ? "--" : session?.netAmount + " " + session?.currency}</TableCell>
                                                <TableCell align='center'>
                                                    {session.paymentStatus === "pending" ? (
                                                        '--'
                                                    ) : (
                                                        <span
                                                            onClick={() => handleTransactionDetailsClick(session)}
                                                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                        >
                                                            view
                                                        </span>
                                                    )}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {session.paymentStatus === "pending" || session.paymentStatus === "Rejected" ? (
                                                        '--'
                                                    ) : (
                                                        <Typography
                                                            component="a"
                                                            href={session?.invoicePdfUrl}
                                                            target='_blank'
                                                            download
                                                            sx={{
                                                                textDecoration: 'none',
                                                                color: 'primary.main',
                                                                cursor: 'pointer',
                                                                padding: '2px',
                                                                borderRadius: '4px',
                                                                fontSize: "12px",
                                                                '&:hover': { backgroundColor: '#f0f0f0' }
                                                            }}
                                                        >
                                                            <PictureAsPdfIcon sx={{ verticalAlign: 'middle', marginRight: 0.5 }} />
                                                            Download
                                                        </Typography>
                                                    )}
                                                </TableCell>

                                                <TableCell align='center' onClick={() => handleDetailsClick(session)}
                                                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}>view</TableCell>
                                                <TableCell align='center'>{session.paymentStatus}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                                sx={{
                                    position: "sticky",
                                    bottom: 0,
                                    overflowX: "hidden",
                                    padding: "0 0.5rem",
                                    background: "#f5f3f3",
                                    borderTop: "1px solid #e0e0e0",
                                    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "0 0 8px 8px",
                                }}
                                count={totalSessions}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        {/* Session Details Popup */}
                            <ModalComponent open={openSessionDetails} handleClose={handleCloseSessionDetails} headerText={"Session Details"} showSubmitButton={false}>
                            {techloading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}><CircularProgress /></Box> :
                                <TableContainer
                                    sx={{
                                        maxHeight: "55vh",
                                        overflow: "auto",
                                        borderRadius: "8px 8px 0 0",
                                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                                        border: "1px solid #e0e0e0",
                                        backgroundColor: "#ffffff",
                                    }}>

                                    <Table size="small" aria-label="session details">
                                        <TableHead
                                            sx={{
                                                position: "sticky",
                                                top: 0,
                                                zIndex: 99,
                                                "& th": {
                                                    border: "1px solid #e0e0e0",
                                                    background: "#F5F3F3",
                                                    fontWeight: "500",
                                                    color: "#333",
                                                },
                                                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                                            }}>
                                            <TableRow>
                                                <TableCell align='center'>Timestamp</TableCell>
                                                <TableCell align='center'>Event Name</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody
                                            sx={{
                                                "& td": {
                                                    border: "1px solid #e0e0e0",
                                                    color: "#555",
                                                },
                                                "& tr:nth-of-type(odd)": {
                                                    backgroundColor: "#fafafa",
                                                },
                                                "& tr:hover": {
                                                    backgroundColor: "#f1f1f1",
                                                },
                                            }}>

                                            {sessionTechData.map((session) => (
                                                <TableRow>
                                                    <TableCell align='center'>{new Date(session?.createdAt).toLocaleString()}</TableCell>
                                                    <TableCell align='center'>{session?.eventName == "StatusNotification" ? session?.eventName + " " + `(${session?.ocppSchema?.status})` : session?.eventName}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>}
                            </ModalComponent>
                        <ModalComponent
                            open={openTransactionDetails}
                            handleClose={handleCloseTransactionDetails}
                            headerText={"Transaction Details"}
                            showSubmitButton={false}
                        >
                            {selectedSession && (
                                <TableContainer
                                    sx={{
                                        maxHeight: "55vh",
                                        overflow: "auto",
                                        borderRadius: "8px 8px 0 0",
                                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                                        border: "1px solid #e0e0e0",
                                        backgroundColor: "#ffffff",
                                    }}
                                >
                                    <Table size="small" aria-label="session details">
                                        <TableHead
                                            sx={{
                                                position: "sticky",
                                                top: 0,
                                                zIndex: 99,
                                                "& th": {
                                                    border: "1px solid #e0e0e0",
                                                    background: "#F5F3F3",
                                                    fontWeight: "500",
                                                    color: "#333",
                                                },
                                                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                                            }}
                                        >
                                            <TableRow>
                                                <TableCell align="center">Energy Consumed</TableCell>
                                                <TableCell align="center">Base Rate Per KWh</TableCell>
                                                <TableCell align="center">Base fare</TableCell>
                                                <TableCell align="center">Duration</TableCell>
                                                <TableCell align="center">Parking Rate Per Hour</TableCell>
                                                <TableCell align="center">Parking Fee</TableCell>
                                                <TableCell align="center">Subtotal</TableCell>
                                                <TableCell align="center">Penalty</TableCell>
                                                <TableCell align="center">Discount</TableCell>
                                                <TableCell align="center">Tax Rate</TableCell>
                                                <TableCell align="center">Total Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody
                                            sx={{
                                                "& td": {
                                                    border: "1px solid #e0e0e0",
                                                    color: "#555",
                                                },
                                                "& tr:nth-of-type(odd)": {
                                                    backgroundColor: "#fafafa",
                                                },
                                                "& tr:hover": {
                                                    backgroundColor: "#f1f1f1",
                                                },
                                            }}
                                        >
                                            <TableRow>
                                                <>
                                                    <TableCell align="center">{selectedSession?.effectiveEnergyConsumed}</TableCell>
                                                    <TableCell align="center">{selectedSession?.effectiveBaseRate}</TableCell>
                                                    <TableCell align="center">{selectedSession?.baseFare}</TableCell>
                                                    <TableCell align="center">{formatDuration(selectedSession?.chargingDuration)}</TableCell>
                                                    <TableCell align="center">{selectedSession?.parkingRatePerHour}</TableCell>
                                                    <TableCell align="center">{selectedSession?.parkingFee}</TableCell>
                                                    <TableCell align="center">{selectedSession?.grossAmount}</TableCell>
                                                    <TableCell align="center">{selectedSession?.penaltyAmount}</TableCell>
                                                    <TableCell align="center">{selectedSession?.discount}</TableCell>
                                                    <TableCell align="center">{selectedSession?.taxRate}</TableCell>
                                                    <TableCell align="center">{selectedSession?.netAmount + " " + selectedSession?.currency}</TableCell>
                                                </>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </ModalComponent>
                    </>
                )}

            </div>

        </MainLayout>
    );
};

export default SessionTable;
