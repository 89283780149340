import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Drawer,
  IconButton,
} from "@mui/material";
import {
  Menu,
  ChevronRight,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import AdminSidebarItems from "./sidebarItemAdmin";
import EVSEOwnerSidebarItems from "./sidebarItemEvseOwner";
import cpoItems from "./sidebarItemCpo";
import styles from "./sidebar.module.scss";
import Pusher from "pusher-js";
import { WhoAmI } from "../../redux/apis/user";
import { setEvse, setCharger } from "../../redux/slices/filter/filterSlice";
import { useDispatch, useSelector } from "react-redux";


const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const SidebarContent = styled.div`
  padding: 0 10px;
  background-color: #002f69;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-size: 10px;
`;

const StyledTypography = styled(Typography)`
  margin-top: 10px;
`;

const ResponsiveLogo = styled(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150px"
    height="40px"
    viewBox="0 0 438.951 124.677"
  >
    <g
      id="Group_268"
      data-name="Group 268"
      transform="translate(-180.875 -140.715)"
    >
      <g id="Group_264" data-name="Group 264">
        <g id="chargnex-logo" transform="translate(180.875 140.714)">
          <path
            id="Union_7"
            data-name="Union 7"
            d="M203.933,97.494V90.269H218.5c6.08,0,9.061-2.409,9.176-7.341h-16.4c-9.75,0-14.567-4.932-14.567-14.566V59.3c0-9.75,4.932-14.567,14.567-14.567h29.133V82.928c0,9.75-4.932,14.566-14.566,14.566Zm5.505-38.079v9.06c0,4.818,2.983,7.226,9.061,7.226h9.061V52.189H218.5C212.421,52.189,209.438,54.6,209.438,59.415ZM311.29,82.928c-11.011,0-16.4-3.9-16.4-11.813V55.63c0-7.226,4.932-10.9,14.566-10.9h12.731c11.012,0,16.4,3.67,16.4,10.9v1.835c0,7.226-5.506,10.9-16.4,10.9h-9.061c-3.555,0-5.39,1.262-5.39,3.67s1.72,3.67,5.39,3.67h25.464v7.226Zm-3.67-27.184V61.48c1.148-.115,2.408-.229,3.67-.229h9.061c3.556,0,5.391-1.262,5.391-3.671V55.744c0-2.408-1.721-3.67-5.391-3.67h-7.226C309.57,52.074,307.735,53.222,307.62,55.744ZM276.652,82.928V59.3c0-4.817-2.983-7.226-9.061-7.226H258.53v.115h-.115V82.928H245.683V44.734h29.134c9.749,0,14.566,4.932,14.566,14.567V82.928Zm-129.15,0V44.734h29.133c9.75,0,14.566,4.932,14.566,14.567H178.47c0-4.817-2.983-7.226-9.061-7.226h-9.175V82.928Zm-67.443,0V59.3c0-4.817-2.982-7.226-9.061-7.226H61.822V82.928H49.09V32H61.822V44.734h16.4c9.75,0,14.566,4.932,14.566,14.567V82.928Zm-58.152,0C7.225,82.928,0,74.441,0,57.465S7.34,32,21.907,32H43.815v7.226H25.577c-8.488,0-12.731,6.194-12.731,18.237q0,18.237,12.731,18.237H43.815v7.226Zm92.905-.114c-11.011,0-16.4-3.67-16.4-10.9V70.082c0-7.112,5.505-10.667,16.4-10.667h9.061c3.556,0,5.391-1.262,5.391-3.785,0-2.408-1.72-3.67-5.391-3.67H98.41V44.734h27.3c11.012,0,16.4,3.9,16.4,11.813V82.813ZM111.257,70.2v1.835c0,2.409,1.72,3.67,5.39,3.67H129.38V66.526H116.647C113.092,66.641,111.257,67.9,111.257,70.2Z"
            transform="translate(0 0)"
            fill="#fff"
          />
        </g>
        <path
          id="Path_807"
          data-name="Path 807"
          d="M306.983,34.833h17.549l14.911,18.352L376.376,5.7,339.443,40.568l-9.979-12.043h-39l30.51,35.556-40.488,51.041H275.9l6.079,8.6,6.308-7.914h.229L331.07,63.967Z"
          transform="translate(221.541 141.668)"
          fill="#fff"
        />
        <path
          id="Path_808"
          data-name="Path 808"
          d="M395.255,8.617,389.176-.1,381.95,8.961h.344l-40.488,49.32,24.087,29.133H348.229L333.318,69.063,296.5,116.548,333.433,81.68l9.979,12.043h39L351.9,58.167l39.342-49.55Z"
          transform="translate(224.57 140.815)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
))`
  width: 100%;
`;




const StyledList = styled(List)`
  padding-top: 10px;
  padding-right: 4px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    height: 4px !important;
    width: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #23cb10;
    border-radius: 15px;
  }
`;

const ResponsiveListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.active ? "#23CB10" : "transparent")};

  &:hover {
    background-color: ${(props) => (props.active ? "#007f3f" : "#005aad")};
  }
`;

const SidebarArrowIcon = styled(({ active, ...props }) =>
  active ? <ExpandLess {...props} /> : <ExpandMore {...props} />
)`
  color: white;
`;

const Sidebar = ({ isSidebarOpen, toggleSidebar, isMobile }) => {
  const [deviceManagementOpen, setDeviceManagementOpen] = useState(false);
  const [chargerMgmtOpen, setChargerMgmtOpen] = useState(false);
  const [financialMgmtOpen, setFinancialMgmtOpen] = useState(false);
  const [userMgmtOpen, setUserMgmtOpen] = useState(false);
  const location = useLocation();
  const userDetails = JSON.parse(localStorage.getItem(`userDetails`) || sessionStorage.getItem(`userDetails`));
  const userRole = userDetails?.category;
  const dispatch = useDispatch();
  const cpoDetails = (userRole === "CPO Admin") ? JSON.parse(localStorage.getItem(`cpoDetails`) || sessionStorage.getItem(`cpoDetails`)) : "";
  const eMspDetails = (userRole === "Super Admin") ? JSON.parse(localStorage.getItem(`eMspDetails`) || sessionStorage.getItem(`eMspDetails`)) : "";
  const [Logo, setLogo] = useState("");
  const cpoEndpoint = useSelector((state) => state.cpo.endpoint) || "cpo";
  // Fetch on Profile Picture Update

  useEffect(() => {
    
    const whoami = async () => {
      try {
        const response = await WhoAmI();
        if (response?.settings?.cpo?.profilePicture) {
          setLogo(response?.settings?.cpo?.profilePicture);
        }
        else if(response?.settings?.eMsp?.profilePicture){
          setLogo(response?.settings?.eMsp?.profilePicture);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
    }
  };
  whoami();
  }, []);

  useEffect(() => {
    
    if (location.pathname !== "/device-management/monitor") {
      dispatch(setEvse(""));
      dispatch(setCharger(""));
    }

  }, [location.pathname, dispatch]);

  useEffect(() => {

    if (userRole !== "CPO Admin" || !cpoDetails?.id) return;
    // Initialize Pusher
    setLogo("");

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe(cpoDetails?.id);

    // Bind to the event
    channel.bind(`account-updated`, (data) => {   
      const updatedLogo = `${data.profilePicture}?v=${new Date().getTime()}`;
        console.log(updatedLogo); // For debugging, logs the updated URL
        setLogo(updatedLogo);

    });

    // Clean up subscription on component unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };

  }, []);

  useEffect(() => {

    if (userRole !== "Super Admin" || !eMspDetails?.id) return;
    // Initialize Pusher
    setLogo("");

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe(eMspDetails?.id);

    // Bind to the event
    channel.bind(`account-updated`, (data) => {   
      const updatedLogo = `${data.profilePicture}?v=${new Date().getTime()}`;
        console.log(updatedLogo); // For debugging, logs the updated URL
        setLogo(updatedLogo);
    });

    // Clean up subscription on component unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };

  }, []);

  const CpoSidebarItems = cpoItems();
  let sidebarItems = [];
  switch (userRole) {
    case "Super Admin":
      sidebarItems = AdminSidebarItems;
      break;
    case "factory_manager":
      sidebarItems = EVSEOwnerSidebarItems;
      break;
    case "CPO Admin":
      sidebarItems = CpoSidebarItems;
      break;
    default:
      break;
  }

  useEffect(() => {
    // Close dropdowns when the location changes
    if (location?.pathname?.includes("device-management")) {
      setDeviceManagementOpen(true);
    } else {
      setDeviceManagementOpen(false);
    }
    
    if (location?.pathname?.includes(`/charger-management`)) {
      setChargerMgmtOpen(true);
    } else {
      setChargerMgmtOpen(false);
    }

    if (location?.pathname?.includes(`/financial-management`)) {
      setFinancialMgmtOpen(true);
    } else {
      setFinancialMgmtOpen(false);
    }

    if (location?.pathname?.includes(`/user-management`)) {
      setUserMgmtOpen(true);
    } else {
      setUserMgmtOpen(false);
    }
  }, [location.pathname]);

  const toggleDeviceManagement = (e) => {
    e.preventDefault();
    setDeviceManagementOpen((prevOpen) => !prevOpen);
  };

  const toggleChargerMgmt = (e) => {
    e.preventDefault();
    setChargerMgmtOpen((prevOpen) => !prevOpen);
  };

  const toggleFinancialMgmt = (e) => {
    e.preventDefault();
    setFinancialMgmtOpen((prevOpen) => !prevOpen);
  };

  const toggleUserMgmt = (e) => {
    e.preventDefault();
    setUserMgmtOpen((prevOpen) => !prevOpen);
  };

  const renderSidebarItem = (item) => {
    const { name, icon: Icon, route, children } = item;
    const isActive = location.pathname === route;

    if (name === "Device Mgmt") {
      return (
        <div key={route}>
          <StyledLink to={route} onClick={toggleDeviceManagement}>
            <ResponsiveListItem
              button
              active={isActive}
              sx={{ borderRadius: "14px", mb: 1 }}
              className={deviceManagementOpen ? styles.activedm : ""}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>{Icon}</ListItemIcon>
              <ListItemText primary={name} />
              <SidebarArrowIcon
                active={deviceManagementOpen}
                fontSize="small"
              />
            </ResponsiveListItem>
          </StyledLink>
          {deviceManagementOpen &&
            children.map((child) => (
              <StyledLink to={child.route} key={child.route}>
                <ResponsiveListItem
                  button
                  active={location.pathname === child.route}
                  sx={{ borderRadius: "14px", mb: 1 }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {child.icon}
                  </ListItemIcon>
                  <ListItemText primary={child.name} />
                  {location.pathname === child.route && (
                    <ChevronRight
                      active={location.pathname === child.route}
                      fontSize="small"
                    />
                  )}
                </ResponsiveListItem>
              </StyledLink>
            ))}
        </div>
      );
    }

    if (name === "User Management") {
      return (
        <div key={route}>
          <StyledLink to={route} onClick={toggleUserMgmt}>
            <ResponsiveListItem
              button
              active={isActive}
              sx={{ borderRadius: "14px", mb: 1 }}
              className={userMgmtOpen ? styles.activedm : ""}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>{Icon}</ListItemIcon>
              <ListItemText primary={name} />
              <SidebarArrowIcon
                active={userMgmtOpen}
                fontSize="small"
              />
            </ResponsiveListItem>
          </StyledLink>
          {userMgmtOpen &&
            children.map((child) => (
              <StyledLink to={child.route} key={child.route}>
                <ResponsiveListItem
                  button
                  active={location.pathname === child.route}
                  sx={{ borderRadius: "14px", mb: 1 }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {child.icon}
                  </ListItemIcon>
                  <ListItemText primary={child.name} />
                  {location.pathname === child.route && (
                    <ChevronRight
                      active={location.pathname === child.route}
                      fontSize="small"
                    />
                  )}
                </ResponsiveListItem>
              </StyledLink>
            ))}
        </div>
      );
    }

    if (name === "Charger Management") {
      return (
        <div key={route}>
          <StyledLink to={route} onClick={toggleChargerMgmt}>
            <ResponsiveListItem
              button
              active={isActive}
              sx={{ borderRadius: "14px", mb: 1 }}
              className={chargerMgmtOpen ? styles.activedm : ""}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>{Icon}</ListItemIcon>
              <ListItemText primary={name} />
              <SidebarArrowIcon
                active={chargerMgmtOpen}
                fontSize="small"
              />
            </ResponsiveListItem>
          </StyledLink>
          {chargerMgmtOpen &&
            children.map((child) => (
              <StyledLink to={child.route} key={child.route}>
                <ResponsiveListItem
                  button
                  active={location.pathname === child.route}
                  sx={{ borderRadius: "14px", mb: 1 }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {child.icon}
                  </ListItemIcon>
                  <ListItemText primary={child.name} />
                  {location.pathname === child.route && (
                    <ChevronRight
                      active={location.pathname === child.route}
                      fontSize="small"
                    />
                  )}
                </ResponsiveListItem>
              </StyledLink>
            ))}
        </div>
      );
    }

    if (name === "Financial Management") {
      return (
        <div key={route}>
          <StyledLink to={route} onClick={toggleFinancialMgmt}>
            <ResponsiveListItem
              button
              active={isActive}
              sx={{ borderRadius: "14px", mb: 1 }}
              className={financialMgmtOpen ? styles.activedm : ""}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>{Icon}</ListItemIcon>
              <ListItemText primary={name} />
              <SidebarArrowIcon
                active={financialMgmtOpen}
                fontSize="small"
              />
            </ResponsiveListItem>
          </StyledLink>
          {financialMgmtOpen &&
            children.map((child) => (
              <StyledLink to={child.route} key={child.route}>
                <ResponsiveListItem
                  button
                  active={location.pathname === child.route}
                  sx={{ borderRadius: "14px", mb: 1 }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {child.icon}
                  </ListItemIcon>
                  <ListItemText primary={child.name} />
                  {location.pathname === child.route && (
                    <ChevronRight
                      active={location.pathname === child.route}
                      fontSize="small"
                    />
                  )}
                </ResponsiveListItem>
              </StyledLink>
            ))}
        </div>
      );
    }

    return (
      <StyledLink to={route} key={route}>
        <ResponsiveListItem
          button
          active={isActive}
          sx={{ borderRadius: "14px", mb: 1 }}
        >
          <ListItemIcon sx={{ minWidth: "30px" }}>{Icon}</ListItemIcon>
          <ListItemText primary={name} />
          {isActive && <ChevronRight active={isActive} fontSize="small" />}
        </ResponsiveListItem>
      </StyledLink>
    );
  };

  return (
    <>
      {isMobile ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleSidebar}
          >
            <Menu />
          </IconButton>
          <Drawer
            variant="persistent"
            anchor="left"
            open={isSidebarOpen}
          >
            <SidebarContent>
              <StyledTypography component="div">
               {(Logo !== "") ? <img src={Logo} style = {{objectFit: "cover",  width: "100%", maxHeight: "50px"}} alt="Chargnex" /> : `${cpoDetails?.name}` } 
              </StyledTypography>
              <StyledList>
                {sidebarItems.map((item) => (
                  <React.Fragment key={item.name}>
                    {renderSidebarItem(item)}
                  </React.Fragment>
                ))}
              </StyledList>
            </SidebarContent>
          </Drawer> 
        </>
      ) : (
        <SidebarContent>
          <StyledTypography component="div" sx={{ display: "flex", justifyContent: "center" }}>
          {(Logo !== "") ? <img src={Logo} style = {{objectFit: "contain", width: "100%", maxHeight: "50px"}} alt="Chargnex" /> : `${cpoDetails?.name}` } 
          </StyledTypography>
          <StyledList>
            {sidebarItems.map((item) => (
              <React.Fragment key={item.name}>
                {renderSidebarItem(item)}
              </React.Fragment>
            ))}
          </StyledList>
        </SidebarContent>
      )}
    </>
  );
};

export default Sidebar;
