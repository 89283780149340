import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
  Button,
  Chip,
  Typography,
  Box
} from "@mui/material";
import ModalComponent from "../../components/atoms/ModalComponent";
import { alpha } from "@mui/material/styles";


const dummyData = [
  {
    id: 1,
    transactionId: "TXN-1234565",
    transactionType: "Purchase",
    grossAmount: "$12",
    netAmount: "$11.1",
    fee: "$0.9",
    currency: "CAD",
    availableOn: "17th Jan '24",
    settlementStatus: "Completed",
    settlementDate: "Jan 13, 2022",
    time: "10:00 AM",
    action: "View",
  },
  {
    id: 2,
    transactionId: "TXN-5678091",
    transactionType: "Purchase",
    grossAmount: "$15",
    netAmount: "$13.2",
    fee: "$1.8",
    currency: "CAD",
    availableOn: "17th Jan '24",
    settlementStatus: "Completed",
    settlementDate: "Jan 13, 2022",
    time: "10:00 AM",
    action: "View",
  },
  {
    id: 3,
    transactionId: "TXN-1372748",
    transactionType: "Purchase",
    grossAmount: "$27",
    netAmount: "$25",
    fee: "$2",
    currency: "INR",
    availableOn: "18th Jan '24",
    settlementStatus: "Failed",
    settlementDate: "Jan 13, 2022",
    time: "10:00 AM",
    action: "Retry",
  },
  {
    id: 4,
    transactionId: "TXN-2847238",
    transactionType: "Purchase",
    grossAmount: "$42",
    netAmount: "$41.1",
    fee: "$0.9",
    currency: "CAD",
    availableOn: "17th Jan '24",
    settlementStatus: "Scheduled",
    settlementDate: "Jan 12, 2022",
    time: "10:00 AM",
    action: "Cancel",
  },
  {
    id: 5,
    transactionId: "TXN-3778234",
    transactionType: "Purchase",
    grossAmount: "$66",
    netAmount: "$65.2",
    fee: "$0.8",
    currency: "INR",
    availableOn: "17th Jan '24",
    settlementStatus: "Completed",
    settlementDate: "Jan 12, 2022",
    time: "10:00 AM",
    action: "View",
  },
  {
    id: 6,
    transactionId: "TXN-7887899",
    transactionType: "Purchase",
    grossAmount: "$66",
    netAmount: "$65.2",
    fee: "$0.8",
    currency: "INR",
    availableOn: "17th Jan '24",
    settlementStatus: "Completed",
    settlementDate: "Jan 12, 2022",
    time: "10:00 AM",
    action: "Request Settlement",
  },
];

const getStatusChipStyles = (status, theme) => {
    switch (status) {
      case "Completed":
        return {
          backgroundColor: alpha(theme.palette.success.main, 0.1),
          color: theme.palette.success.dark,
        };
      case "Failed":
        return {
          backgroundColor: alpha(theme.palette.error.main, 0.1),
          color: theme.palette.error.dark,
        };
      case "Scheduled":
        return {
          backgroundColor: alpha(theme.palette.info.main, 0.1),
          color: theme.palette.info.dark,
        };
      default:
        return {
          backgroundColor: alpha(theme.palette.grey[500], 0.1),
          color: theme.palette.text.primary,
        };
    }
  };

const ActivityTable = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        setSelectedRows(dummyData.map((row) => row.id));
      } else {
        setSelectedRows([]);
      }
    };
  
    const handleRowSelect = (id) => {
      setSelectedRows((prev) =>
        prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
      );
    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const handleOpenModal = (row, action) => {
      setModalContent({ ...row, action });
      setOpenModal(true);
    };
  
    const handleCloseModal = () => {
      setOpenModal(false);
    };
  
    return (
      <>
        <TableContainer component={Paper} sx={{ maxHeight: "45vh" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
              <TableCell align="center">Serial Number</TableCell>
                <TableCell align="center">Transaction ID</TableCell>
                <TableCell align="center">Transaction Type</TableCell>
                <TableCell align="center">Gross Amount</TableCell>
                <TableCell align="center">Net Amount</TableCell>
                <TableCell align="center">Fee</TableCell>
                <TableCell align="center">Currency</TableCell>
                <TableCell align="center">Available On</TableCell>
                <TableCell align="center">Settlement Status</TableCell>
                <TableCell align="center">Settlement Date/Arrived on</TableCell>
                <TableCell align="center">Time</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dummyData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={row.id} hover>
                    <TableCell align="center">{1 + index + rowsPerPage*page }</TableCell>
                    <TableCell align="center">{row.transactionId}</TableCell>
                    <TableCell align="center">{row.transactionType}</TableCell>
                    <TableCell align="center">{row.grossAmount}</TableCell>
                    <TableCell align="center">{row.netAmount}</TableCell>
                    <TableCell align="center">{row.fee}</TableCell>
                    <TableCell align="center">{row.currency}</TableCell>
                    <TableCell align="center">{row.availableOn}</TableCell>
                    <TableCell align="center">
                      <Chip
                        label={row.settlementStatus}
                        size="small"
                        sx={(theme) => getStatusChipStyles(row.settlementStatus, theme)}
                      />
                    </TableCell>
                    <TableCell align="center">{row.settlementDate}</TableCell>
                    <TableCell align="center">{row.time}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleOpenModal(row, row.action)}
                      >
                        {row.action}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={dummyData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
  
        {/* Modal Component */}
        <ModalComponent open={openModal} handleClose={handleCloseModal} headerText={"Settlement Details"} showSubmitButton={false}>
          <Box >
            <Typography variant="h6" component="h2">
              {modalContent.action} Details
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <strong>Transaction ID:</strong> {modalContent.transactionId}
            </Typography>
            <Typography>
              <strong>Settlement Status:</strong> {modalContent.settlementStatus}
            </Typography>
            <Typography>
              <strong>Date:</strong> {modalContent.settlementDate}
            </Typography>
            <Typography>
              <strong>Time:</strong> {modalContent.time}
            </Typography>
            {modalContent.action === "Retry" && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 2 }}
                onClick={() => alert("Retry logic here")}
              >
                Retry
              </Button>
            )}
            {modalContent.action === "Cancel" && (
              <Button
                variant="contained"
                color="error"
                sx={{ mt: 2 }}
                onClick={() => alert("Cancel logic here")}
              >
                Cancel
              </Button>
            )}
          </Box>
        </ModalComponent>
      </>
    );
  };
  
  export default ActivityTable;