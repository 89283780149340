import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Pusher from "pusher-js";
import { message } from "antd";
import { invokeAPI } from "../../redux/apis/rapid";
import { useSelector } from "react-redux";
import { CircularProgress, Box } from "@mui/material";

function ServiceTab(props) {
  const [services, setServices] = useState([]);
  const selectedChargerFromRedux = useSelector((state) => state.filter.charger);
  const selectedEvseFromRedux = useSelector((state) => state.filter.evse);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setServices([]);
  }, [selectedEvseFromRedux === "" && selectedChargerFromRedux === ""]);


  const bindWithChunking = (channel, event, callback) => {
    let events = {};
    channel.bind(event, (data) => {
      if (!events.hasOwnProperty(data.id)) {
        events[data.id] = { chunks: [], receivedFinal: false };
      }
      let ev = events[data.id];
      ev.chunks[data.index] = data.chunk;
      if (data.final) ev.receivedFinal = true;

      if (ev.receivedFinal && ev.chunks.length === Object.keys(ev.chunks).length) {
        try {
          const validJsonString = `[${ev.chunks.join(",")}]`;
          const chunkArray = JSON.parse(validJsonString);
          const serviceData = chunkArray[0];
          if (serviceData && Array.isArray(serviceData.services)) {
            callback(serviceData.services);
          }
        } catch (err) {
          console.error("Error parsing service chunk:", err);
        }
        delete events[data.id];
      }
    });
  };
  useEffect(() => {
    if (selectedChargerFromRedux) {
      setLoading(true);
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      });

      const channel = pusher.subscribe(selectedChargerFromRedux.serialNumber);


      // Use the bindWithChunking function to handle incoming chunked process data
      bindWithChunking(channel, "CHUNKED_LIVE_SYSTEMCTL_LIST", (serviceData) => {
        console.log("serviceData", serviceData);
        setServices((prevServices) => [...prevServices, ...serviceData]);
        setLoading(false);
      });


      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [selectedChargerFromRedux]);

  const ActionBtn = (name, action) => {
    invokeAPI({
      type: "SERVICE_ACTION",
      deviceId: selectedChargerFromRedux?.serialNumber,
      serviceName: name,
      action: action,
      reset: false,
    })
      .then(() => {
        message.success(`Service ${action} Action Successful`);
      })
      .catch(() => {
        message.error(`Service ${action} Action Failed`);
      });
  };

  return (
    <>
      {
        loading ?
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box >
          :
          <Typography component={"div"} padding={"1rem"}>
            <Typography component={"div"} sx={{ mt: "1rem" }}>
              <Table
                sx={{ minWidth: 650, textTransform: "capitalize" }}
                aria-label="service table"
              >
                <TableHead
                  sx={{
                    "& td, & th": { border: "1px solid #4C4E641F !important" },
                    background: "#F5F3F3 0% 0% no-repeat padding-box",
                  }}
                >
                  <TableRow>
                    <TableCell align="center">Service Name</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">State</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {services.map((service, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "& td, & th": {
                          border: "1px solid #4C4E641F !important",
                        },
                      }}
                    >
                      <TableCell align="center">{service.name}</TableCell>
                      <TableCell align="center">{service.status}</TableCell>
                      <TableCell align="center">
                        <p
                          style={{
                            color:
                              service.state === "Running"
                                ? "green"
                                : service.state === "Failed"
                                  ? "red"
                                  : "brown",
                            margin: "0px",
                          }}
                        >
                          {service.state}
                        </p>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          onClick={() => ActionBtn(service.name, "start")}
                          size="small"
                          style={{
                            backgroundColor: service.status !== "Inactive" ? "#cdd1cf" : "green",
                            borderRadius: "20px",
                            color: "white",
                            marginRight: "1em",
                            paddingRight: 20,
                            paddingLeft: 20,
                          }}
                          disabled={service.status !== "Inactive"}
                        >
                          Start
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => ActionBtn(service.name, "stop")}
                          size="small"
                          style={{
                            backgroundColor: service.status === "Inactive" ? "#cdd1cf" : "red",
                            borderRadius: "20px",
                            color: "white",
                            paddingRight: 20,
                            paddingLeft: 20,
                          }}
                          disabled={service.status === "Inactive"}
                        >
                          Stop
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Typography>
          </Typography>
      }
    </>
  );
}

export default ServiceTab;
