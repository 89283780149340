import React from "react";
import styles from "./secondaryHeader.module.scss";
import { Typography } from "@mui/material";
import Button from "../atoms/button/button";
import TabComponent from "../atoms/tabComponent/tabComponent";
import MonitorTab from "../atoms/tabComponent/MonitorTab";
import AnalyticsTab from "../atoms/tabComponent/analyticsTab";
import SupportTab from "../atoms/tabComponent/supportTab";
import FilterComponent from "../atoms/filterComponent.jsx";
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import QtaFilterComponent from "../atoms/otaFilterComponent.jsx";
import { Box } from "@material-ui/core";

function SecondaryHeader({ setActiveTab }) {

  const useLocationfromrouter = useLocation();
  const location = useLocationfromrouter.pathname;
  const cpoEndpoint = useSelector((state) => state.cpo.endpoint) || "cpo";


  let pageName = "";
  switch (location) {
    case "/chargers":
      pageName = "Charger";
      break;
    case "/evse-stations":
      pageName = "EVSE Station";
      break;
    case `/csms/${cpoEndpoint}/charger-management/chargers`:
      pageName = "Charger";
      break;
    case `/csms/${cpoEndpoint}/charger-management/evse-stations`:
      pageName = "EVSE Station";
      break;
    case `/csms/${cpoEndpoint}/settings`:
      pageName = "Settings";
      break;
    case "/device-management/ota-updates":
      pageName = "OTA Updates";
      break;
  }

  return (location !== "/home" && location !== "/site-host-admin" && location !== "/cpo" && location !== "/device-management/ota-updates" && location !== `/csms/${cpoEndpoint}/home` && location !== "/evse-stations" && location !== "/sites" && location !== "/site-host-operator" && location !== `/csms/${cpoEndpoint}/customers` && location !== `/csms/${cpoEndpoint}/integration` && location !== `/csms/${cpoEndpoint}/charger-management/evse-stations` && location !== `/csms/${cpoEndpoint}/financial-management/transaction-history` && location !== `/csms/${cpoEndpoint}/financial-management/settlements`) && (
    <div className={styles.secondary_header} >
      {location === "/settings" ? (
        <TabComponent {...{ setActiveTab }} />
      ) : location === `/csms/${cpoEndpoint}/settings` ? (<TabComponent {...{ setActiveTab }} />) : location === "/device-management/monitor" ? (
        <Typography
          component={"div"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <MonitorTab {...{ setActiveTab }} />
          <Box sx={{ ml: "auto" }}> {/* Ensure QtaFilterComponent is pushed to the right */}
            <QtaFilterComponent />
          </Box>
        </Typography>

      ) : location === "/analytics" ? (
        <AnalyticsTab {...{ setActiveTab }} />
      ) :
        location === `/csms/${cpoEndpoint}/analytics` ? (
          <AnalyticsTab {...{ setActiveTab }} />
        ) : location === "/support" ? (
          <SupportTab {...{ setActiveTab }} />
        ) : location === `/csms/${cpoEndpoint}/support` ? (
          <SupportTab {...{ setActiveTab }} />
        ) : (
          <Typography
            component={"div"}
            hidden={location === "/dev-tools" || location}
            // className={styles.secondary_header_filter}
            sx={{
              width: { xs: "100%", sm: "50%", md: "50%" },
              display: "flex",
              justifyContent: "flex-end",
              marginLeft: { sm: "auto" },
              alignItems: { xs: "flex-start", sm: "center" },
              flexDirection: { xs: "column", sm: "row", md: "row" },
            }}
          >
            <Typography
              component={"div"}
              fontSize={14}
              textAlign={"left"}
              marginLeft={"15px"}
              marginTop={{ xs: "4px", sm: "0px", md: "0px" }}
              marginRight={"20px"}
            >
              Filter {pageName}:{" "}
            </Typography>
            <Typography>
              <FilterComponent />
            </Typography>
          </Typography>
        )}
    </div>
  );
}

export default SecondaryHeader;
