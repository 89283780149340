import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_API_URL}/api`;

const axiosInstance = axios.create({
  baseURL,
});

// Function to set bearer token
export const setAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Ensure token is set before each API call
const ensureAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    console.log(token);
    setAuthToken(token);
  }
};

// API Calls

export const updatemyProfile = async (data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.post(`/user/update-profile`, data);
    return response.data;
  }catch(error){
    throw error;
  }
};

export const fetchRoles = async () => {
    ensureAuthToken();
    try{
      const response = await axiosInstance.get('/user-role');
      return response.data;
    }catch(error){
      throw error;
    }
  
  };
  
  export const AddRole = async (data) => {
    ensureAuthToken();
    try{
      const response = await axiosInstance.post('/user-role', data);
      return response.data;
    }catch(error){
      throw error;
    }
  };
  
  export const updateRole = async (roleId, data) => {
    ensureAuthToken();
    try{
      const response = await axiosInstance.patch(`/user-role/${roleId}`, data);
      return response.data;
    }catch(error){
      throw error;
    }
  };
  
  export const deleteRole = async (roleId) => {
    ensureAuthToken();
    try{
      const response = await axiosInstance.delete(`/user-role/${roleId}`);
      return response.data;
    }catch(error){
      throw error;
    }
  };


  export const fetchEMSPUsers = async (queryParams) => {
    ensureAuthToken();
    try{
      const response = await axiosInstance.get('/user', {params: queryParams});
      return response.data;
    }catch(error){
      throw error;
    }
  };
  
  export const fetchEMSPUserById = async (userId) => {
    ensureAuthToken();
    try{
      const response = await axiosInstance.get(`/user/${userId}`);
      return response.data;
    }catch(error){
      throw error;
    }
  }
  
  export const addEMSPUser = async (data) => {
    ensureAuthToken();
    try{
      const response = await axiosInstance.post('/user', data);
      return response.data;
    }catch(error){
      throw error;
    }
  };
  
  export const updateEMSPUser = async (userId, data) => {
    ensureAuthToken();
    try{
      const response = await axiosInstance.patch(`/user/${userId}`, data);
      return response.data;
    }catch(error){
      throw error;
    }
  };
  
  export const deleteEMSPUser = async (userId) => {
    ensureAuthToken();
    try{
      const response = await axiosInstance.delete(`/user/${userId}`);
      return response.data;
    }catch(error){
      throw error;
    }
  };

  export const updateEMSPAdminAccountSettings = async (data) => {
    ensureAuthToken();
    try{
      const response = await axiosInstance.post(`/user/update-account-settings`, data);
      return response.data;
    }catch(error){
      throw error;
    }
  }