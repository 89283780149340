import React, { useEffect, useState } from 'react';
import HomeIcon from '../icons/homeIcon';
import EsevIcon from '../icons/esevIcon';
import ChargersIcon from '../icons/chargersIcon';
import AnalyticsIcon from '../icons/analyticsIcon';
import SettingsIcon from '../icons/settingsIcon';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import FinanceIcon from '@mui/icons-material/AccountBalanceWallet';
import { WhoAmI } from '../../redux/apis/user';
import { useSelector } from 'react-redux';

const SiteHostAdminSidebarItems = () => {
  // State for the cpo endpoint
  const cpoEndpoint = useSelector((state) => state.cpo.endpoint) || "cpo";


  // Sidebar items
  const sidebarItems = [
    {
      name: "Home",
      icon: <HomeIcon />,
      route: `/csms/${cpoEndpoint}/home`,
    },
    {
      name: "User Management",
      icon: <SupportAgentIcon sx={{ color: "#fff", padding: "0", margin: "0", maxWidth: "15px", height: "15px" }} />,
      route: `/user-management`,
      children: [
        {
          name: "My Users",
          route: `/csms/${cpoEndpoint}/user-management/my-users`,
        },
        {
          name: "My Customers",
          route: `/csms/${cpoEndpoint}/user-management/my-customers`,
        }
      ],
    },
    {
      name: "Charger Management",
      icon: <ChargersIcon />,
      route: `/charger-management`,
      children: [
        {
          name: "EVSE Stations",
          route: `/csms/${cpoEndpoint}/charger-management/evse-stations`,
        },
        {
          name: "Chargers",
          route: `/csms/${cpoEndpoint}/charger-management/chargers`,
        },
        {
          name: "Base Rate Management",
          route: `/csms/${cpoEndpoint}/charger-management/base-rate-management`,
        },
        {
          name: "Charging Sessions",
          route: `/csms/${cpoEndpoint}/charger-management/sessions`
        }
      ],
    },
    {
      name: "Financial Management",
      icon: <FinanceIcon sx={{ color: "#fff", padding: "0", margin: "0", maxWidth: "15px", height: "15px" }} />,
      route: `/financial-management`,
      children: [
        {
          name: "Transaction History",
          route: `/csms/${cpoEndpoint}/financial-management/transaction-history`,
        },
        {
          name: "Settlements",
          route: `/csms/${cpoEndpoint}/financial-management/settlements`,
        },
      ],
    },
    {
      name: "Performance & Analytics",
      icon: <AnalyticsIcon />,
      route: `/csms/${cpoEndpoint}/analytics`,
    },
    {
      name: "Integration",
      icon: <IntegrationInstructionsIcon sx={{ color: "#fff", padding: "0", margin: "0", maxWidth: "15px", height: "15px" }} />,
      route: `/csms/${cpoEndpoint}/integration`,
    },
    {
      name: "Settings",
      icon: <SettingsIcon />,
      route: `/csms/${cpoEndpoint}/settings`,
    },
    {
      name: "Help and Support",
      icon: <SettingsIcon />,
      route: `/csms/${cpoEndpoint}/support`,
    },
  ];

  return sidebarItems; // Return the sidebar items
};

export default SiteHostAdminSidebarItems;
