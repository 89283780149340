// Overview.js
import React, { useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider
} from '@mui/material';

const Overview = () => {

    return (
        <Box
        sx={{
            padding: 2,
            overflow: "auto",
            maxHeight: "50vh",
        }}
    >
        <Card
            sx={{
                width: "100%",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Lighter shadow
                borderRadius: 2,
                
            }}
        >
            <CardContent>
                <Typography variant="h6" color="primary" gutterBottom>
                    Your Balance
                </Typography>
                <Typography>On the way to your bank account: $0</Typography>
                <Typography>Funds on hold: $342,500</Typography>
                <Typography
                    sx={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "green",
                        marginTop: 1,
                    }}
                >
                    Total: $342,500
                </Typography>
            </CardContent>
        </Card>
    
        <Card
            sx={{
                width: "100%",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Lighter shadow
                borderRadius: 2,
                
            }}
        >
            <CardContent>
                <Typography variant="h6" color="primary" gutterBottom>
                    Upcoming to your bank
                </Typography>
                <Typography>
                    These funds will start being paid out again once you have resolved the issue with your
                    bank account.
                </Typography>
                <Typography
                    sx={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "orange",
                        marginTop: 1,
                    }}
                >
                    Total: $342,500
                </Typography>
            </CardContent>
        </Card>
    
        <Card
            sx={{
                width: "100%",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Lighter shadow
                borderRadius: 2,
                
            }}
        >
            <CardContent>
                <Typography variant="h6" color="primary" gutterBottom>
                    On the way to your bank
                </Typography>
                <Typography>Settlement Receivable Date: October 15, 2024</Typography>
                <Typography
                    sx={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "gray",
                        marginTop: 1,
                    }}
                >
                    Total Amount: $0
                </Typography>
            </CardContent>
        </Card>
    
        <Card
            sx={{
                width: "100%",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Lighter shadow
                borderRadius: 2,
            }}
        >
            <CardContent>
                <Typography variant="h6" color="primary" gutterBottom>
                    Recent Settlements
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="Settlement Receivable Date: October 12, 2024" />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary="Settlement Receivable Date: October 10, 2024" />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary="Settlement Receivable Date: October 8, 2024" />
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    </Box>
    

    );
};

export default Overview;