import React, { useState, useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import ModalComponent from "../../components/atoms/ModalComponent";
import Barcode from "react-barcode";
import { Print } from "@mui/icons-material";
import { getSessionsList, getTransactions, fetchOcppLogsAPI } from "../../redux/apis/chargers";
import { useReactToPrint } from "react-to-print";
import { QRCodeSVG } from 'qrcode.react'; // Import QR code component
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import './ChargerDetailsComponent.css';
import TextField from "@mui/material/TextField";
import axios from "axios";
import { activateChargerAPI } from "../../redux/apis/chargers";
import { message } from "antd";
import Pusher from "pusher-js";

const tableStyles = {
  minWidth: 650,
  textTransform: "capitalize",
  "& td, & th": {
    border: "1px solid #4C4E641F !important",
  },
};

const ChargerDetailsContainer = {
  display: "flex",
  flexWrap: "wrap",
  gap: "1rem",
  marginBottom: "1rem",
};

const ChargerInfo = {
  flex: 1,
  minWidth: "200px",
};

const pageStyle = `
@page {
  size: 200mm 200mm; /* Adjust the size as needed */
  margin: 0; /* Remove margins */
}

@media all {
  .pageBreak {
    display: none;
  }
}
@media print {
  .pageBreak {
    page-break-before: always;
  }

  /* Center the content for print */
  .pageContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}
`;

const ChargerDetailsComponent = ({ selectedCharger, open, handleClose, fetchChargers }) => {
  const [sessions, setSessions] = useState([]);
  const [technicaldetails, setTechnicaldetails] = useState([]);
  const [ocppLogs, setOcppLogs] = useState([]);
  const [transactionsPage, setTransactionsPage] = useState(0);
  const [transactionsRowsPerPage, setTransactionsRowsPerPage] = useState(10);
  const [sessionsPage, setSessionsPage] = useState(0);
  const [sessionsRowsPerPage, setSessionsRowsPerPage] = useState(10);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [sessionsLoading, setSessionsLoading] = useState(false);
  const [ocppLogsLoading, setOcppLogsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0); // State for tab index
  const [activateChargerForm, setActivateChargerForm] = useState({
    serialNumber: "",
    activationCode: "",
    lat: "",
    lng: ""
  })
  const [selectedSession, setSelectedSession] = useState(null);
  const [isActivateChargerModalOpen, setIsActivateChargerModalOpen] = useState(false);
  const [openTransactionDetails, setOpenTransactionDetails] = useState(false);
  const [totalTechDetailsCount, setTotalTechDetailsCount] = useState(0);
  const [totalTransactionsCount, setTotalTransactionsCount] = useState(0);


  useEffect(() => {
    if (open && selectedCharger) {
      // Clear the state before fetching new data
      setSessions([]);
      setTechnicaldetails([]);
      setOcppLogs([]);
      fetchOcppLogs();
      fetchTransactions();
      fetchTechnicalDetails();
    }
  }, [open, selectedCharger, transactionsPage, transactionsRowsPerPage, sessionsPage, sessionsRowsPerPage, tabIndex]);

  const fetchTechnicalDetails = async () => {
    setSessionsLoading(true);
    try {
      const response = await getSessionsList(selectedCharger?.chargeBoxId, {
        page: sessionsPage + 1,
        limit: sessionsRowsPerPage,
        sort:"createdAt:desc"
      });
      setTechnicaldetails(response.list || []);
      setTotalTechDetailsCount(response.totalCount || 0);
      
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
    finally {
      setSessionsLoading(false);
    }
  }

  useEffect(() => {
    if (selectedCharger) {
      fetchTechnicalDetails();
    }
  }, [selectedCharger, sessionsPage, sessionsRowsPerPage]);

  const fetchOcppLogs = async () => {
    setOcppLogsLoading(true);
    try {
      const response = await fetchOcppLogsAPI(selectedCharger?.id, {
        limit: Infinity,
        sort:"createdAt:desc"
      });
      setOcppLogs(response.list || []);
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
    setOcppLogsLoading(false);
  };

  useEffect(() => {
    if (selectedCharger) {
      fetchOcppLogs();
    }
  }, [selectedCharger]);

  const refreshOcppLogs = () => {
    fetchOcppLogs();
  };

  const fetchTransactions = async () => {
    setTransactionsLoading(true);
    try {
      const response = await getTransactions({
        filter: `{"chargeBoxId":"${selectedCharger?.chargeBoxId}"}`,
        page: transactionsPage + 1,
        limit: transactionsRowsPerPage,
          sort:"createdAt:desc"
      });
      setSessions(response.list || []);
      setTotalTransactionsCount(response.totalCount)
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
    setTransactionsLoading(false);
  };

  useEffect(() => {
    if (selectedCharger) {
      fetchTransactions();
    }
  }, [selectedCharger, transactionsPage, transactionsRowsPerPage]);

  const barcodeRef = useRef(null); // Reference to the barcode content
  const qrCodeRef = useRef(null); // Reference to the QR code content

  // Setup the print functions using useReactToPrint
  const handlePrintBarcode = useReactToPrint({
    content: () => barcodeRef.current,
  });

  const handlePrintQRCode = useReactToPrint({
    content: () => qrCodeRef.current,
  });

  const formatSerialNumber = (serialNumber) => {
    const formattedSerialNumber = `${serialNumber.slice(0, 4)}-${serialNumber.slice(4, 8)}-${serialNumber.slice(8, 12)}-${serialNumber.slice(12, 16)}`;
    return formattedSerialNumber;
  }

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleActivateCharger = async () => {
    try {
      activateChargerForm.lat = selectedCharger.evseStationLat;
      activateChargerForm.lng = selectedCharger.evseStationLng;
      const response = await activateChargerAPI(activateChargerForm);
      console.log(response);
      if (response.status === "activated") {
        setIsActivateChargerModalOpen(false);
        message.success("Charger activated successfully");
        handleClose();
        fetchChargers();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Failed to activate charger");
    }
  }

  const openActivateModal = () => {
    setActivateChargerForm({ ...activateChargerForm, serialNumber: selectedCharger.serialNumber });
    setIsActivateChargerModalOpen(true);
  }

  const handleCloseActivateChargerModal = () => {
    setIsActivateChargerModalOpen(false);
  }

  const isChargerActive = ["registered"].includes(selectedCharger?.status);


  const handleTransactionDetailsClick = (sessionData) => {
    setSelectedSession(sessionData);
    setOpenTransactionDetails(true);
  };

  const handleCloseTransactionDetails = () => {
    setOpenTransactionDetails(false);
  };

  function formatDuration(durationInSeconds) {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
  
    // Format with leading zeros if needed
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
  
    return `${formattedHours} hr : ${formattedMinutes} min`;
  }

  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe('pusher-node-app');

    channel.bind('charger-updated', (data) => {
      // Fetch chargers data again when the event is triggered
      updateSelectedCharger(data);
    });
    // Clean up on unmount
    return () => {
      channel.unbind('charger-updated');
      pusher.unsubscribe('pusher-node-app');
    };
  }, []);

  const updateSelectedCharger = (data) => {
    selectedCharger.activationCode = data.activationCode;
  };

  return (
    <>
      <ModalComponent open={open} handleClose={handleClose} headerText={"Charger Details"} showSubmitButton={false}>
        {selectedCharger && (
          <div>
            <div style={ChargerDetailsContainer}>
              <div style={ChargerInfo}>
                <Typography variant="body1">Serial Number: {formatSerialNumber(selectedCharger.serialNumber)}</Typography>
                <Typography variant="body1">ChargeBox ID: {selectedCharger.chargeBoxId}</Typography>
                <Typography variant="body1">Model Name: {selectedCharger.chargerModel || "Prime"}</Typography>
                <Typography variant="body1">EVSE Station: {selectedCharger.evseStationAddress}</Typography>
                <Typography variant="body1">Activated: {!isChargerActive ? "Yes" : "No"}</Typography>
                {isChargerActive && (
                  <>
                    <Typography variant="body1">Activation Code: {selectedCharger.activationCode}</Typography>
                  </>
                )}
              </div>
              {/* Barcode Section */}
              <div>
                <div
                  ref={barcodeRef}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="barcodeContainer">
                    <Barcode
                      value={selectedCharger.serialNumber}
                      className="barcodeSvg"
                    />
                  </div>
                </div>
                <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    style={{ color: "white", gap: "0.5rem", marginTop: "10px", maxHeight: "40px" }}
                    color="primary"
                    onClick={handlePrintBarcode}
                  >
                    <Print style={{ color: "#fff" }} />
                    Print Barcode
                  </Button>
                </Typography>
              </div>
              {/* QR Code Section */}
              <div>
                <div
                  ref={qrCodeRef}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="qrCodeContainer">
                    <QRCodeSVG
                      value={selectedCharger.serialNumber}
                      size={128}
                      level="H"
                      includeMargin={true}
                    />
                  </div>
                </div>
                <Button
                  variant="contained"
                  style={{ color: "white", gap: "0.5rem", maxHeight: "40px" }}
                  color="primary"
                  onClick={handlePrintQRCode}
                >
                  <Print style={{ color: "#fff" }} />
                  Print QR Code
                </Button>
              </div>
            </div>

            {/* Tabs for sessions, technicaldetails, and OCPP Logs */}
            <Box sx={{ width: '100%' }}>
              <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="Charger Details Tabs">
                <Tab label="Sessions" />
                <Tab label="Technical Details" />
                <Tab label="OCPP Logs" />
              </Tabs>
              {tabIndex === 0 && (
                <div>
                  <Typography variant="h5" gutterBottom style={{ marginTop: "1rem" }}>
                    Sessions
                  </Typography>
                  {transactionsLoading ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <div>
                      <TableContainer
                        sx={{
                          maxHeight: "55vh",
                          overflow: "auto",
                          borderRadius: "8px 8px 0 0",
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                          border: "1px solid #e0e0e0",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <Table sx={tableStyles}>
                          <TableHead
                            sx={{
                              position: "sticky",
                              top: 0,
                              zIndex: 99,
                              "& th": {
                                border: "1px solid #e0e0e0",
                                background: "#F5F3F3",
                                fontWeight: "500",
                                color: "#333",
                              },
                              boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <TableRow>
                              <TableCell align='center'>Sr. No</TableCell>
                              <TableCell align='center'>Session ID</TableCell>
                              <TableCell align='center'>Date</TableCell>
                              <TableCell align='center'>Customer Name</TableCell>
                              <TableCell align='center'>Start Time</TableCell>
                              <TableCell align='center'>End Time</TableCell>
                              <TableCell align='center'>Total Amount</TableCell>
                              <TableCell align='center'>Transaction Details</TableCell>
                              <TableCell align='center'>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              "& td": {
                                border: "1px solid #e0e0e0",
                                color: "#555",
                              },
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: "#fafafa",
                              },
                              "& tr:hover": {
                                backgroundColor: "#f1f1f1",
                              },
                            }}
                          >
                            {sessions
                              .map((session, index) => (
                                <TableRow key={session.transactionUuid}>
                                  <TableCell align='center'>{transactionsPage * transactionsRowsPerPage + index + 1}</TableCell>
                                  <TableCell align='left'>{session?.transactionUuid}</TableCell>
                                  <TableCell align='center'>{new Date(session?.createdAtLocal).toLocaleDateString()}</TableCell>
                                  <TableCell align='center'>{session?.customerName || '--'}</TableCell>
                                  <TableCell align='center'>{new Date(session?.startTimeLocal).toLocaleTimeString()}</TableCell>
                                  <TableCell align='center'>{session.paymentStatus === "pending" ? "--" : new Date(session?.endTimeLocal).toLocaleTimeString()}</TableCell>
                                  <TableCell align='center'>{session.paymentStatus === "pending" ? "--" : session?.netAmount + " " + session?.currency}</TableCell>
                                  <TableCell align='center'>
                                    {session.paymentStatus === "pending" ? (
                                      '--'
                                    ) : (
                                      <span
                                        onClick={() => handleTransactionDetailsClick(session)}
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                      >
                                        view
                                      </span>
                                    )}
                                  </TableCell>
                                  <TableCell align='center'>{session.paymentStatus}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        component="div"
                        sx={{
                          bottom: 0,
                          overflowX: "auto",
                          padding: "0 0.5rem",
                          background: "#f5f3f3",
                          borderTop: "1px solid #e0e0e0",
                          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                          borderRadius: "0 0 8px 8px",
                        }}
                        count={totalTransactionsCount}
                        rowsPerPage={transactionsRowsPerPage}
                        page={transactionsPage}
                        onPageChange={(event, newPage) => setTransactionsPage(newPage)}
                        onRowsPerPageChange={(event) => {
                          setTransactionsRowsPerPage(parseInt(event.target.value, 10));
                          setTransactionsPage(0);
                        }}
                      />
                    </div>
                  )}
                  <ModalComponent
                    open={openTransactionDetails}
                    handleClose={handleCloseTransactionDetails}
                    headerText={"Transaction Details"}
                    showSubmitButton={false}
                  >
                    {selectedSession && (
                      <TableContainer
                        sx={{
                          maxHeight: "55vh",
                          overflow: "auto",
                          borderRadius: "8px 8px 0 0",
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                          border: "1px solid #e0e0e0",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <Table size="small" aria-label="session details">
                          <TableHead
                            sx={{
                              position: "sticky",
                              top: 0,
                              zIndex: 99,
                              "& th": {
                                border: "1px solid #e0e0e0",
                                background: "#F5F3F3",
                                fontWeight: "500",
                                color: "#333",
                              },
                              boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <TableRow>
                              <TableCell align="center">Energy Consumed</TableCell>
                              <TableCell align="center">Base Rate Per KWh</TableCell>
                              <TableCell align="center">Base fare</TableCell>
                              <TableCell align="center">Duration</TableCell>
                              <TableCell align="center">Parking Rate Per Hour</TableCell>
                              <TableCell align="center">Parking Fee</TableCell>
                              <TableCell align="center">Subtotal</TableCell>
                              <TableCell align="center">Penalty</TableCell>
                              <TableCell align="center">Discount</TableCell>
                              <TableCell align="center">Tax Rate</TableCell>
                              <TableCell align="center">Total Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              "& td": {
                                border: "1px solid #e0e0e0",
                                color: "#555",
                              },
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: "#fafafa",
                              },
                              "& tr:hover": {
                                backgroundColor: "#f1f1f1",
                              },
                            }}
                          >
                            <TableRow>
                              <>
                                <TableCell align="center">{selectedSession?.effectiveEnergyConsumed}</TableCell>
                                <TableCell align="center">{selectedSession?.effectiveBaseRate}</TableCell>
                                <TableCell align="center">{selectedSession?.baseFare}</TableCell>
                                <TableCell align="center">{formatDuration(selectedSession?.chargingDuration)}</TableCell>
                                <TableCell align="center">{selectedSession?.parkingRatePerHour}</TableCell>
                                <TableCell align="center">{selectedSession?.parkingFee}</TableCell>
                                <TableCell align="center">{selectedSession?.grossAmount}</TableCell>
                                <TableCell align="center">{selectedSession?.penaltyAmount}</TableCell>
                                <TableCell align="center">{selectedSession?.discount}</TableCell>
                                <TableCell align="center">{selectedSession?.taxRate}</TableCell>
                                <TableCell align="center">{selectedSession?.netAmount}</TableCell>
                              </>
                            </TableRow>

                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </ModalComponent>
                </div>
              )}
              {tabIndex === 1 && (
                <div>
                  <Typography variant="h5" gutterBottom style={{ marginTop: "1rem" }}>
                    Technical Details
                  </Typography>
                  {sessionsLoading ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <div>
                      <TableContainer
                        sx={{
                          maxHeight: "55vh",
                          overflow: "auto",
                          borderRadius: "8px 8px 0 0",
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                          border: "1px solid #e0e0e0",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <Table sx={tableStyles}>
                          <TableHead
                            sx={{
                              position: "sticky",
                              top: 0,
                              zIndex: 99,
                              "& th": {
                                border: "1px solid #e0e0e0",
                                background: "#F5F3F3",
                                fontWeight: "500",
                                color: "#333",
                              },
                              boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <TableRow>
                              <TableCell align="center">Sr. No</TableCell>
                              <TableCell align='center'>Timestamp</TableCell>
                              <TableCell align='center'>Event Name</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              "& td": {
                                border: "1px solid #e0e0e0",
                                color: "#555",
                              },
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: "#fafafa",
                              },
                              "& tr:hover": {
                                backgroundColor: "#f1f1f1",
                              },
                            }}>
                            {technicaldetails
                              .map((session, index) => (
                                <TableRow key={session._id}>
                                  <TableCell align="center">{index + 1 + sessionsPage * sessionsRowsPerPage}</TableCell>
                                  <TableCell align='center'>{new Date(session?.createdAt).toLocaleString()}</TableCell>
                                  <TableCell align='center'>{session?.eventName == "StatusNotification" ? session?.eventName + " " + `(${session?.ocppSchema?.status})` : session?.eventName}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        component="div"
                        sx={{
                          bottom: 0,
                          overflowX: "auto",
                          padding: "0 0.5rem",
                          background: "#f5f3f3",
                          borderTop: "1px solid #e0e0e0",
                          boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                          borderRadius: "0 0 8px 8px",
                        }}
                        count={totalTechDetailsCount}
                        rowsPerPage={sessionsRowsPerPage}
                        page={sessionsPage}
                        onPageChange={(event, newPage) => setSessionsPage(newPage)}
                        onRowsPerPageChange={(event) => {
                          setSessionsRowsPerPage(parseInt(event.target.value, 10));
                          setSessionsPage(0);
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              {tabIndex === 2 && (
                <div>
                  <Typography variant="div" gutterBottom style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h5">OCPP Logs</Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={refreshOcppLogs}
                    >
                      Refresh
                    </Button>
                  </Typography>
                  {ocppLogsLoading ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <TextField
                      value={ocppLogs.map(log => JSON.stringify(log)).join('\n\n')}
                      multiline
                      fullWidth
                      variant="outlined"
                      rows={15} // Adjust the number of visible rows as needed
                      InputProps={{
                        readOnly: true,
                        style: {
                          fontFamily: "monospace",
                          whiteSpace: "pre",
                          overflowX: "auto",
                          backgroundColor: "#f5f5f5",
                          padding: "1rem",
                          borderRadius: "8px"
                        }
                      }}
                    />
                  )}
                </div>
              )}
            </Box>
          </div>
        )}
      </ModalComponent>
      <ModalComponent
        open={isActivateChargerModalOpen}
        handleClose={handleCloseActivateChargerModal}
        headerText={"Activate Charger"}
        submitButtonLabel={"Activate"}
        handleSubmit={handleActivateCharger}
      >
        <div>
          <TextField
            label="Serial Number"
            value={activateChargerForm.serialNumber}
            disabled
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Activation Code"
            value={activateChargerForm.activationCode}
            onChange={(e) => setActivateChargerForm({ ...activateChargerForm, activationCode: e.target.value })}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
        </div>
      </ModalComponent>
    </>
  );
};

export default ChargerDetailsComponent;
