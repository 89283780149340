import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Chip,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import RetryIcon from '@mui/icons-material/Replay';
import { alpha } from "@mui/material/styles";
import ModalComponent from '../../components/atoms/ModalComponent';

// Function to generate random asset data (executed only once)
const generateAssetData = () => {
  const statusOptions = ['Completed', 'Failed', 'Scheduled'];
  const bankNames = ['Bank of Montreal', 'Chase', 'HSBC', 'Citibank'];
  const settlementIds = Array.from({ length: 20 }, (_, i) => `ST-2024-${i + 1}`);

  return settlementIds.map((id) => {
    let transactions = generateTransactionData(id);
    while (transactions.some((tx) => tx.status === 'Failed')) {
      transactions = generateTransactionData(id);
    }

    const status = statusOptions[Math.floor(Math.random() * statusOptions.length)];
    const amount = transactions.reduce((sum, tx) => sum + tx.grossAmount, 0);
    const bank = bankNames[Math.floor(Math.random() * bankNames.length)];
    const settlementDate = new Date(2024, 0, Math.floor(Math.random() * 30) + 1).toLocaleDateString();
    const time = `${Math.floor(Math.random() * 12) + 1}:00 AM`;

    return { settlementId: id, amount: `$${amount}`, bank, status, settlementDate, time, transactions };
  });
};

// Function to generate transaction data for each settlement
const generateTransactionData = (settlementId) => {
  const transactionDates = Array.from({ length: 5 }, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() - i);
    return date.toLocaleDateString();
  });

  return transactionDates.map((date, index) => ({
    srNo: index + 1,
    transactionType: 'Purchase',
    grossAmount: Math.floor(Math.random() * 100) + 10,
    fee: (Math.random() * 5).toFixed(2),
    netAmount: null, // Calculate dynamically
    availableOn: date,
    status: 'Completed', // Ensures no failure transactions
  })).map((tx) => ({
    ...tx,
    netAmount: (tx.grossAmount - tx.fee).toFixed(2),
  }));
};

// Store the generated data once outside of the component
const initialData = generateAssetData();

const SettlementsTab = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSettlement, setSelectedSettlement] = useState(null);
  const [transactionPage, setTransactionPage] = useState(0);
  const [transactionRowsPerPage, setTransactionRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewDetails = (settlement) => {
    setSelectedSettlement(settlement);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleTransactionChangePage = (event, newPage) => {
    setTransactionPage(newPage);
  };

  const handleTransactionChangeRowsPerPage = (event) => {
    setTransactionRowsPerPage(parseInt(event.target.value, 10));
    setTransactionPage(0);
  };

  const getStatusChipStyles = (status, theme) => {
    switch (status) {
      case "Completed":
        return {
          backgroundColor: alpha(theme.palette.success.main, 0.1),
          color: theme.palette.success.dark,
        };
      case "Failed":
        return {
          backgroundColor: alpha(theme.palette.error.main, 0.1),
          color: theme.palette.error.dark,
        };
      case "Scheduled":
        return {
          backgroundColor: alpha(theme.palette.info.main, 0.1),
          color: theme.palette.info.dark,
        };
      default:
        return {
          backgroundColor: alpha(theme.palette.grey[500], 0.1),
          color: theme.palette.text.primary,
        };
    }
  };

  // Function to determine active step based on the status
  const getActiveStep = (status) => {
    switch (status) {
      case 'Completed':
        return 2; // "Completed" is the last step
      case 'Scheduled':
        return 1; // "Scheduled" goes to the second step
      case 'Failed':
        return 0; // "Failed" is at the first step
      default:
        return 0;
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: '10px' }}>
        View and verify your settlements status
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: '40vh' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">Serial Number</TableCell>
              <TableCell align="center">Settlement ID</TableCell>
              <TableCell align="center">Settlement Amount</TableCell>
              <TableCell align="center">Bank/Card</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Settlement Date/Arrived on</TableCell>
              <TableCell align="center">Time</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {initialData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={row.settlementId}>
                <TableCell align="center">{index + page * rowsPerPage + 1}</TableCell>
                <TableCell align="center">{row.settlementId}</TableCell>
                <TableCell align="center">{row.amount}</TableCell>
                <TableCell align="center">{row.bank}</TableCell>
                <TableCell align="center">
                  <Chip label={row.status} size="small" sx={(theme) => getStatusChipStyles(row.status, theme)} />
                </TableCell>
                <TableCell align="center">{row.settlementDate}</TableCell>
                <TableCell align="center">{row.time}</TableCell>
                <TableCell align="center">
                  {row.status === 'Failed' ? (
                    <Button
                      variant="outlined"
                      startIcon={<RetryIcon />}
                      onClick={() => console.log(`Retrying settlement ${row.settlementId}`)}
                    >
                      Retry
                    </Button>
                  ) : (
                    <Button variant="outlined" onClick={() => handleViewDetails(row)}>
                      View Details
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={initialData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ModalComponent open={openModal} handleClose={handleCloseModal} title={`Settlement Details for ${selectedSettlement?.settlementId}`} showSubmitButton={false}>
        {selectedSettlement && (
          <>
            <Box sx={{ display: 'flex', gap: 5, alignItems: 'center', marginBottom: '15px' }}>
              <div>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Settlement ID: {selectedSettlement.settlementId}
                </Typography>
                <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>
                  Settlement Amount: {selectedSettlement.amount}
                </Typography>
              </div>
              {selectedSettlement.status === 'Completed' && (
                <Typography
                  sx={{
                    padding: '5px 10px',
                    border: '1px solid #d3d3d3',
                    borderRadius: '4px',
                    color: 'green',
                    fontWeight: 'bold',
                  }}
                >
                  Paid
                </Typography>
              )}
            </Box>


            {/* Dynamic Stepper with active step based on status */}
            <Stepper activeStep={getActiveStep(selectedSettlement.status)} orientation="vertical">
              <Step>
                <StepLabel>Payout Initiated (Date: {selectedSettlement.settlementDate}, Time: {selectedSettlement.time})</StepLabel>
              </Step>
              <Step>
                <StepLabel>Processing</StepLabel>
              </Step>
              <Step>
                <StepLabel>Completed</StepLabel>
              </Step>
            </Stepper>

            <Box sx={{ marginY: '20px' }}>
              <Typography variant="subtitle1" gutterBottom>
                Transaction Summary
              </Typography>
              <Typography variant="body2">Transaction Count: {selectedSettlement.transactions.length}</Typography>
              <Typography variant="body2">Total Gross Amount: ${selectedSettlement.transactions.reduce((sum, tx) => sum + tx.grossAmount, 0)}</Typography>
              <Typography variant="body2">Total Fees: ${selectedSettlement.transactions.reduce((sum, tx) => sum + parseFloat(tx.fee), 0).toFixed(2)}</Typography>
              <Typography variant="body2">Net Amount: ${selectedSettlement.transactions.reduce((sum, tx) => sum + parseFloat(tx.netAmount), 0).toFixed(2)}</Typography>
            </Box>

            <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>
              Transactions
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Sr. No.</TableCell>
                    <TableCell align="center">Transaction Type</TableCell>
                    <TableCell align="center">Gross Amount</TableCell>
                    <TableCell align="center">Fee</TableCell>
                    <TableCell align="center">Net Amount</TableCell>
                    <TableCell align="center">Available On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedSettlement.transactions.slice(transactionPage * transactionRowsPerPage, transactionPage * transactionRowsPerPage + transactionRowsPerPage).map((transaction, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{transaction.srNo}</TableCell>
                      <TableCell align="center">{transaction.transactionType}</TableCell>
                      <TableCell align="center">${transaction.grossAmount}</TableCell>
                      <TableCell align="center">${transaction.fee}</TableCell>
                      <TableCell align="center">${transaction.netAmount}</TableCell>
                      <TableCell align="center">{transaction.availableOn}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={selectedSettlement.transactions.length}
              rowsPerPage={transactionRowsPerPage}
              page={transactionPage}
              onPageChange={handleTransactionChangePage}
              onRowsPerPageChange={handleTransactionChangeRowsPerPage}
            />
          </>
        )}
      </ModalComponent>
    </Box>
  );
};

export default SettlementsTab;
