import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Chip,
} from '@mui/material';
import MainLayout from '../../layout/mainLayout';
import ModalComponent from '../../components/atoms/ModalComponent';
import { alpha } from '@mui/material/styles';

const generateDummyData = () =>
  Array.from({ length: 50 }, (_, index) => {
    const transactionTypes = ['Refund', 'Purchase', 'Pre-Authorization'];
    const transactionType = transactionTypes[index % transactionTypes.length];
    const statusOptions = {
      'Pre-Authorization': ['Authorized', 'Failed'],
      Refund: ['Approved', 'Denied'],
      Purchase: ['Approved', 'Denied'],
    };
    const status = statusOptions[transactionType][index % statusOptions[transactionType].length];
    return {
      serialNumber: index + 1,
      transactionId: `TXN${1000 + index}`,
      chargerSerialNumber: `343F-A075-8D25-${2000 + index}`,
      transactionType,
      status,
      amount: `$${(Math.random() * 100).toFixed(2)}`,
      currency: 'USD',
      paymentMethod: ['Card', 'UPI'][index % 2],
      cardType: ['Visa', 'Mastercard', 'Discover', 'Amex'][index % 4],
      date: new Date(2024, 0, 18, 16, index).toLocaleString(),
      refundedDate: transactionType === 'Refund' ? new Date(2024, 0, 18, 16, 30 + index).toLocaleString() : '—',
      posEntry: ['Manual', 'Swipe', 'Contactless'][index % 3],
      authAmount: `$${(Math.random() * 50).toFixed(2)}`,
      invoiceNo: `INV${2000 + index}`,
      cardNo: `**** **** **** ${1234 + index}`,
      responseText: ['Approved', 'Declined'][index % 2],
      authId: `AUTH${9000 + index}`,
    };
  });


const PaymentHistory = () => {
  const [data, setData] = useState(generateDummyData());
  const [openModal, setOpenModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleViewDetails = (transaction) => {
    setSelectedTransaction(transaction);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTransaction(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const statusCounts = {
    Authorized: data.filter((item) => item.status === 'Authorized').length,
    Failed: data.filter((item) => item.status === 'Failed').length,
    Approved: data.filter((item) => item.status === 'Approved').length,
    Denied: data.filter((item) => item.status === 'Denied').length,
  };

  const StatusCard = ({ title, count, active }) => (
    <Box
      sx={{
        padding: '10px 15px',
        textAlign: 'left',
        borderRadius: 3,
        border: active ? '2px solid #635BFF' : '1px solid #9ea8b3',
        cursor: 'pointer',
        color: active ? '#635BFF' : '#7e8c9a',
        flexGrow: 1,
      }}
    >
      <Typography sx={{ fontWeight: active ? '600' : 'normal' }}>{title}</Typography>
      <Typography variant="h6" sx={{ color: active ? '#007AFF' : '#272e35', fontWeight: active ? '600' : 'normal' }}>
        {count}
      </Typography>
    </Box>
  );

  const FilterInput = ({ label, ...props }) => (
    <TextField
      label={label}
      variant="outlined"
      size="small"
      sx={{ width: '200px', marginRight: 2 }}
      {...props}
    />
  );

  const getStatusChipStyles = (status, theme) => {
    switch (status) {
      case 'Authorized':
      case 'Approved':
        return {
          backgroundColor: alpha(theme.palette.success.main, 0.1),
          color: theme.palette.success.dark,
        };
      case 'Failed':
      case 'Denied':
        return {
          backgroundColor: alpha(theme.palette.error.main, 0.1),
          color: theme.palette.error.dark,
        };
      default:
        return {
          backgroundColor: alpha(theme.palette.warning.main, 0.1),
          color: theme.palette.warning.dark,
        };
    }
  };

  return (
    <MainLayout>
      <Box display="flex" gap={2} mb={3}>
        <StatusCard title="Authorized" count={statusCounts.Authorized} active />
        <StatusCard title="Failed" count={statusCounts.Failed} />
        <StatusCard title="Approved" count={statusCounts.Approved} />
        <StatusCard title="Denied" count={statusCounts.Denied} />
      </Box>

      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <FilterInput label="Date & Time" />
        <FilterInput label="Amount" />
        <FilterInput label="Currency" />
        <FilterInput label="Payment Method" />
        <Button variant="outlined" size="small">
          Clear Filters
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ maxHeight: '50vh' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">Serial Number</TableCell>
              <TableCell align="center">Transaction ID</TableCell>
              <TableCell align="center">Charger Serial Number</TableCell>
              <TableCell align="center">Transaction Type</TableCell>
              <TableCell align="center">Amount</TableCell>
              <TableCell align="center">Currency</TableCell>
              <TableCell align="center">Payment Method</TableCell>
              <TableCell align="center">Card Type</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Refunded Date</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.serialNumber}>
                <TableCell align="center">{row.serialNumber}</TableCell>
                <TableCell align="center">{row.transactionId}</TableCell>
                <TableCell align="center">{row.chargerSerialNumber}</TableCell>
                <TableCell align="center">{row.transactionType}</TableCell>
                <TableCell align="center">{row.amount}</TableCell>
                <TableCell align="center">{row.currency}</TableCell>
                <TableCell align="center">{row.paymentMethod}</TableCell>
                <TableCell align="center">{row.paymentMethod === 'UPI' ? '--' : row.cardType}</TableCell>
                <TableCell align="center">{row.date}</TableCell>
                <TableCell align="center">{row.refundedDate}</TableCell>
                <TableCell align="center">
                  <Chip
                    label={row.status}
                    size="small"
                    sx={(theme) => getStatusChipStyles(row.status, theme)}
                  />
                </TableCell>
                <TableCell align="center">
                  <Button variant="outlined" size="small" onClick={() => handleViewDetails(row)}>
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ModalComponent
        open={openModal}
        handleClose={handleCloseModal}
        headerText="Transaction Details"
        showSubmitButton={false}
      >
        {selectedTransaction ? (
          <Box padding={2}>
            {/* Transaction ID and Status */}
            <Box marginBottom={3}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Transaction ID: {selectedTransaction.transactionId}
              </Typography>
              <Typography variant="h6" fontWeight="bold" color="success.main" gutterBottom>
                {selectedTransaction.amount} {selectedTransaction.status}
              </Typography>
            </Box>

            {/* Charger Information Table */}
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              Charger Information
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Serial No.</TableCell>
                    <TableCell>{selectedTransaction.chargerSerialNumber || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Model Name</TableCell>
                    <TableCell>{selectedTransaction.modelName || 'Model X'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ChargeBox ID</TableCell>
                    <TableCell>{selectedTransaction.chargeBoxId || 'CHG123'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>EVSE Station Name</TableCell>
                    <TableCell>{selectedTransaction.evseStationName || 'Station A'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* Transaction Information Table */}
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom marginTop={3}>
              Transaction Information
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>TXN Date/Time</TableCell>
                    <TableCell>{selectedTransaction.date || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>TXN Type</TableCell>
                    <TableCell>{selectedTransaction.transactionType || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Payment Type</TableCell>
                    <TableCell>{selectedTransaction.paymentMethod || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Card Type</TableCell>
                    <TableCell>{selectedTransaction.cardType || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Country</TableCell>
                    <TableCell>{selectedTransaction.country || 'USA'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Currency</TableCell>
                    <TableCell>{selectedTransaction.currency || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>POS Entry</TableCell>
                    <TableCell>{selectedTransaction.posEntry || 'Manual'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Auth Amount</TableCell>
                    <TableCell>{selectedTransaction.authAmount || '$50.00'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Invoice No.</TableCell>
                    <TableCell>{selectedTransaction.invoiceNo || 'INV12345'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Card No.</TableCell>
                    <TableCell>{selectedTransaction.cardNo || '**** **** **** 1234'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Response Text</TableCell>
                    <TableCell>{selectedTransaction.responseText || 'Approved'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Auth ID</TableCell>
                    <TableCell>{selectedTransaction.authId || 'AUTH98765'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>TXN Record Time</TableCell>
                    <TableCell>{selectedTransaction.recordTime || '12/10/2024 14:35:00'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <CircularProgress />
        )}
      </ModalComponent>



    </MainLayout>
  );
};

export default PaymentHistory;
