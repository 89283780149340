import React, { useState, useEffect, useCallback } from "react";
import CardStyles from "../../components/atoms/card/index.module.scss";
import {
    Avatar,
    Grid,
    Typography,
    Button,
    TextField,
    IconButton,
    Box,
    CircularProgress,
    Dialog
} from "@mui/material";
import ModalComponent from "../atoms/ModalComponent";
import { updatemyProfile } from "../../redux/apis/emsp";
import { WhoAmI } from "../../redux/apis/user";
import { message } from "antd";
import { PhotoCamera } from "@mui/icons-material";




const MyProfile = () => {
    const [openEditModal, setOpenEditModal] = useState(false);
    const [formData, setFormData] = useState({
        file: null,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        category: "",
    });
    const [errors, setErrors] = useState({});
    const [permissions, setPermissions] = useState({});
    const [loading, setLoading] = useState(false); // Added loading state
    const [updating, setUpdating] = useState(false); // Separate state for updating profile
    const [isProfilePictureUpdated, setIsProfilePictureUpdated] = useState(false);
    const [preview, setPreview] = useState(null);

    const handleOpenEditModal = () => {
        setOpenEditModal(true)
        setErrors({})
    };
    const handleCloseEditModal = () => {
        setOpenEditModal(false)
        fetchUserData();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    //Erro handling
    const validateForm = () => {
        const newErrors = {};
        if (!formData.firstName) {
            newErrors.firstName = "First name is required";
        }
        if (!formData.lastName) {
            newErrors.lastName = "Last name is required";
        }
        if (!formData.email) {
            newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Invalid email address";
        }
        if (!formData.phoneNumber) {
            newErrors.phoneNumber = "Phone number is required";
        }
        if (formData.phoneNumber.length !== 10) {
            newErrors.phoneNumber = "Phone number must be 10 digits";
        }
        if (isNaN(formData.phoneNumber)) {
            newErrors.phoneNumber = "Phone number must be a numeric value";
        }
        if (!formData.category) {
            newErrors.category = "Category is required";
        }
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
    
        if (!file) {
            message.error("No File Selected");
            return;
        }
    
        try {
            setLoading(true);
            const updatedFormData = new FormData();
            updatedFormData.append("file", file);
    
            // Upload image to backend
            await updatemyProfile(updatedFormData);
            fetchUserData();
            message.success("Profile Picture Updated Successfully");
    
            setFormData({ ...formData, file });
            setPreview(URL.createObjectURL(file));
        } catch (error) {
            message.error(error.response?.data?.message || "Error Updating Profile Picture");
        } finally {
            setLoading(false);
        }
    };

    const fetchUserData = async () => {
        setLoading(true);
        try {
            const response = await WhoAmI();
            setPermissions(response.permissions);
            console.log("Permissions:", response.permissions);
            setFormData((prev) => ({
                ...prev,
                file: response.user?.profilePicture,
                firstName: response.user?.firstName,
                lastName: response.user?.lastName,
                email: response.user?.email,
                phoneNumber: response.user?.phoneNumber,
                category: response.userRole
            }));
        } catch (error) {
            console.error("Error fetching permissions:", error);
        } finally {
            setLoading(false);
        }
    };

    const updateProfile = async () => {

        if (!validateForm()) {
            return;
        }

        setUpdating(true); // Start updating state

        try {
            // Update the formData object directly and send to the API
            await updatemyProfile(formData); // Assuming your API can accept JSON data

            message.success("Profile Updated Successfully");
            handleCloseEditModal();
            fetchUserData();
        } catch (error) {
            message.error(error.response.data.message || "Failed To Update Profile");
            console.error("Error updating profile:", error);
        } finally {
            setUpdating(false); // Stop updating regardless of success or error
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [isProfilePictureUpdated]);


    return (
        <>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="55vh">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid
                        className={CardStyles.card}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"left"}
                    >
                        <div style={{ position: "relative", display: "inline-block" }}>
                            <Avatar
                                src={`${formData?.file}`}
                                className="ProfileImg"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "90px",
                                    height: "90px",
                                    borderRadius: "50%",
                                    border: "2px solid #E9E9E9",
                                    "& img": {
                                        objectFit: "contain",
                                        width: "100%",
                                        height: "100%",
                                    },
                                }}
                            />
                            <input
                                accept="image/*"
                                style={{ display: "none" }}
                                id="icon-button-file"
                                type="file"
                                onChange={handleFileChange}
                            />
                            <label htmlFor="icon-button-file">
                                <IconButton
                                    component="span"
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                        background: "rgba(255, 255, 255, 0.7)",
                                        borderRadius: "50%",
                                        boxShadow: 2,
                                    }}
                                >
                                    <PhotoCamera />
                                </IconButton>
                            </label>
                        </div>

                        <Typography
                            sx={{
                                marginLeft: "3rem",
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "4rem",
                            }}
                        >
                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>Name :</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                                    {`${formData.firstName} ${formData.lastName}`}
                                </Typography>
                            </Typography>
                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>Email :</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                                    {formData.email}
                                </Typography>
                            </Typography>
                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>Mobile :</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                                    {formData.phoneNumber}
                                </Typography>
                            </Typography>
                        </Typography>
                        <Typography
                            display={"flex"}
                            flexDirection={"column"}
                            marginLeft={"4rem"}
                            alignItems={"flex-start"}
                            height={"71px"}
                        >
                            <Typography display={"flex"}>
                                <Typography sx={{ fontWeight: "500" }}>Role :</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                                    {formData.category}
                                </Typography>
                            </Typography>
                        </Typography>
                        <Button variant="outlined" onClick={handleOpenEditModal} sx={{ marginLeft: "auto" }}>
                            Edit
                        </Button>
                    </Grid>

                    <Grid xs={12} sx={{ display: "flex", marginTop: "1rem" }}>
                        <Grid className={CardStyles.card} xs={12}>
                            <Typography
                                component={"h1"}
                                fontWeight={"500"}
                                fontSize={"1.25rem"}
                                marginBottom={"0.5rem"}
                            >
                                Permissions
                            </Typography>
                            <Grid sx={{ display: "flex" }}>
                                <Grid
                                    sx={{
                                        background: "#F1F1F1 0% 0% no-repeat padding-box",
                                        borderRadius: "14px",
                                        opacity: "1",
                                        alignItems: "center",
                                        padding: "1rem",
                                        fontSize: "1rem",
                                        display: "flex",
                                    }}
                                    xs={12}
                                    container
                                >
                                    {Object.keys(permissions).map((permission, index) => (
                                        <Grid key={index} xs={6}>
                                            {permission}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}

            <ModalComponent
                open={openEditModal}
                handleClose={handleCloseEditModal}
                headerText={"Edit User Details"}
                handleSubmit={updateProfile}
                submitButtonLabel={updating ? "Updating..." : "Update"}
                isSubmitting={updating}
            >
                <form>
                    <TextField
                        name="firstName"
                        label="First Name"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        error={errors.firstName}
                        helperText={errors.firstName}
                    />
                    <TextField
                        name="lastName"
                        label="Last Name"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        error={errors.lastName}
                        helperText={errors.lastName}
                    />
                    <TextField
                        name="email"
                        label="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        error={errors.email}
                        helperText={errors.email}
                    />
                    <TextField
                        name="phoneNumber"
                        label="Phone Number"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        error={errors.phoneNumber}
                        helperText={errors.phoneNumber}
                    />
                </form>
            </ModalComponent>
        </>
    );
};

export default MyProfile;
