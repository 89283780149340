import { AppBar, Typography } from "@mui/material";
import React, { useState } from "react";
import TermsModal from "./termsModal";
import PrivacyPolicyModal from "./privacyPolicyModal";
import DisclaimerModal from "./disclaimer";

function Footer() {
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [termModalOpen, setTermModalOpen] = useState(false);
  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(false);
  return (
    <AppBar
      sx={{
        height: "2.5rem",
        background: "#FFFFFF",
        display: "flex",
        flexDirection: { sm: "column", md: "row" },
        justifyContent: "space-between",
        alignItems: "center",
        px: "1.5rem",
        py: "1rem",
        position:"absolute",
        top:"auto",
        bottom:0,
        marginBottom:"0px !important"
      }}
    >
      <Typography fontSize={"14px"}>
        @Chargnex. 2024 All Rights Reserved
      </Typography>
      <Typography fontSize={"14px"}>
        <Typography
          component={"a"}
          fontSize={"14px"}
          sx={{textDecoration: "none", color: "#333" }}
          href="https://chargnex.com/terms-conditions.php"
          target="_blank"
        >
          Terms and Conditions
        </Typography>{" "}
        |
        <Typography
          component={"a"}
          fontSize={"14px"}
          sx={{textDecoration: "none", color: "#333" }}
          href={"https://chargnex.com/privacy-policy.php"}
          target="_blank"
        >
          {" "}
          Privacy Policy
        </Typography>{" "}
        | 
        <Typography
          component={"span"}
          fontSize={"14px"}
          sx={{ cursor: "pointer" }}
          onClick={() => setDisclaimerModalOpen(true)}
        >
          {" "}
          Disclaimer
        </Typography>
      </Typography>
      <Typography>A Division of <Typography component={"a"} href={"https://trunexa.com/"} fontSize={"14px"} target="_blank" sx={{textDecoration: "none", color: "#333"}}>Trunexa</Typography></Typography>
      {termModalOpen && (
        <TermsModal
          termModalOpen={termModalOpen}
          setTermModalOpen={setTermModalOpen}
        />
      )}
      {privacyModalOpen && (
        <PrivacyPolicyModal
          privacyModalOpen={privacyModalOpen}
          setPrivacyModalOpen={setPrivacyModalOpen}
        />
      )}
      {disclaimerModalOpen && (
        <DisclaimerModal
        disclaimerModalOpen={disclaimerModalOpen}
        setDisclaimerModalOpen={setDisclaimerModalOpen}
        />
      )}
    </AppBar>
  );
}

export default Footer;
