import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Paper,
  Tabs,
  Tab,
  Box,
  CircularProgress,
} from "@mui/material";
import Pusher from "pusher-js";
import { useSelector } from "react-redux";
import { invokeAPI } from "../../redux/apis/rapid";
import { Card } from "../atoms/card";

function ApplicationLog() {
  const [applicationLogs, setApplicationLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // Track active tab
  const selectedChargerFromRedux = useSelector((state) => state.filter.charger);

  // Tab-specific events
  const tabEvents = [
    "LIVE_APPLICATION_LOGS_Volt",
    "LIVE_APPLICATION_LOGS_Volt_Activator",
    "LIVE_APPLICATION_LOGS_Volt_Debug",
    "LIVE_APPLICATION_LOGS_Volt_Demo",
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setApplicationLogs([]); // Clear logs on tab change
    setLoading(true); // Start loading only when tab is clicked
  };

  const fetchApplicationLogsData = async (event) => {
    try {
      if (selectedChargerFromRedux) {
        await invokeAPI({
          deviceId: selectedChargerFromRedux.serialNumber,
          reset: false,
          type: event,
        });
      }
    } catch (error) {
      console.error("Error fetching Application Logs:", error);
    }
  };

  // Initial Fetch
  useEffect(() => {
    if (selectedChargerFromRedux) {
      setLoading(true);
      fetchApplicationLogsData("LIVE_APPLICATION_LOGS_Volt");
    }
  }, [selectedChargerFromRedux]);

  const bindWithChunking = (channel, event, callback) => {
    let events = {};
    channel.bind(event, (data) => {
      if (!events.hasOwnProperty(data.id)) {
        events[data.id] = { chunks: [], receivedFinal: false };
      }
      let ev = events[data.id];
      ev.chunks[data.index] = data.chunk;
      if (data.final) ev.receivedFinal = true;
      if (
        ev.receivedFinal &&
        ev.chunks.length === Object.keys(ev.chunks).length
      ) {
        try {
          // Parse only `applicationLogs` attribute and split by `\n`
          const fullData = JSON.parse(ev.chunks.join(""));
          if (!fullData?.applicationLogs?.logs) {
            setApplicationLogs([]); // Clear logs
            setLoading(false); // Stop spinner
            return;
          }
          const logsArray = fullData?.applicationLogs?.logs?.split("\n");
          callback(logsArray);
        } catch (err) {
          console.error("Error parsing JSON data:", err);
          setApplicationLogs([]); // Clear logs in case of parse error
          setLoading(false); // Stop spinner
        }
        delete events[data.id];
      }
    });
  };

  useEffect(() => {
    if (selectedChargerFromRedux && activeTab !== null) {
      setLoading(true); // Show spinner when new event is fetched

      const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      });
      const channel = pusher.subscribe(selectedChargerFromRedux.serialNumber);
      const currentEvent = tabEvents[activeTab];

      fetchApplicationLogsData(currentEvent);

      bindWithChunking(channel, "CHUNKED_LIVE_APPLICATION_LOG", (logsArray) => {
        setApplicationLogs(logsArray);
        setLoading(false); // Stop loading when data is received
      });

      // Fallback timeout in case of no data received
      const timeoutId = setTimeout(() => {
        if (applicationLogs.length === 0) {
          setLoading(false);
        }
      }, 10000); // 10 seconds timeout

      return () => {
        clearTimeout(timeoutId);
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [selectedChargerFromRedux, activeTab]);

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob(applicationLogs.map((ele) => `${ele}\n`), {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = `ApplicationLogs_Service${activeTab + 1}.txt`;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <>
      <Card xs={12} md={12}>
        {/* Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Main App" />
            <Tab label="Activator App" />
            <Tab label="Volt Check" />
            <Tab label="Volt Demo" />
          </Tabs>
        </Box>

        {/* Download Button */}
        {applicationLogs.length > 0 && (
          <Box
            sx={{
              fontWeight: "bold",
              width: "100px",
              margin: "10px 20px",
            }}
          >
            <Button
              variant="contained"
              onClick={downloadTxtFile}
              sx={{
                textTransform: "none",
                backgroundColor: "#0fcbd0",
                color: "white",
                borderRadius: "10px",
                height: "25px",
                padding: "10px",
              }}
            >
              Download
            </Button>
          </Box>
        )}

        {/* Logs Content */}
        <Box sx={{ padding: "20px" }}>
          <Paper
            sx={{
              width: "98%",
              overflow: "auto",
              minHeight: "60vh",
              maxHeight: "70vh",
              padding: "10px",
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : applicationLogs.length === 0 ? (
              <Typography>No Logs available</Typography>
            ) : (
              applicationLogs.map((log, index) => (
                <Typography key={index}>{log}</Typography>
              ))
            )}
          </Paper>
        </Box>
      </Card>
    </>
  );
}

export default ApplicationLog;
