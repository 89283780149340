import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_API_URL}/api`;

const axiosInstance = axios.create({
  baseURL,
});

// Function to set bearer token
export const setAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Ensure token is set before each API call
const ensureAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    setAuthToken(token);
  }
};

// API Calls

export const fetchChargerList = async (queryParams) => {
  ensureAuthToken(); // Ensure token is set before the API call
  try {
    const response = await axiosInstance.get('/charger', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error('Error fetching charger list:', error);
    throw error;
  }
};

export const registerChargerAPI = async (data) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.post('/charger/register', data);
    return response.data;
  } catch (error) {
    console.error('Error registering charger:', error);
    throw error;
  }
};

export const assignCpoToChargerAPI = async (chargerId,data) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.post(`/charger/${chargerId}/assign-cpo`, data);
    return response.data;
  } catch (error) {
    console.error('Error assigning CPO to charger:', error);
    throw error;
  }
};

export const assignChargerAPI = async (chargerId, data) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.post(`/charger/${chargerId}/assign-evse-station`, data);
    return response.data;
  } catch (error) {
    console.error('Error assigning EVSE to charger:', error);
    throw error;
  }
};

export const editChargerAPI = async (selectedChargerId, data) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.patch(`/charger/${selectedChargerId}`, data);
    return response.data;
  } catch (error) {
    console.error(`Error editing charger ${selectedChargerId}:`, error);
    throw error;
  }
};

export const deleteChargerAPI = async (chargerID) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.delete(`/charger/${chargerID}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting charger ${chargerID}:`, error);
    throw error;
  }
};

export const fetchConnectorTypeAPI = async (queryParams) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.get('/connector-types', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error('Error fetching connector types:', error);
    throw error;
  }
};

export const fetchUserListAPI = async (queryParams) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.get('/user', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error('Error fetching user list:', error);
    throw error;
  }
};

export const fetchSiteListAPI = async (queryParams) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.get('/site', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error('Error fetching site list:', error);
    throw error;
  }
};


export const handleLogOutAPI = async (queryParams) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.get('/auth/logout', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  }
};

export const fetchChargerLocationAPI = async (queryParams) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.get('/charger/location-map-data', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error('Error fetching charger location map data:', error);
    throw error;
  }
};

export const fetchChargerLocationEvseAssignedAPI = async (chargeboxId) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.post(`/charger/${chargeboxId}/update-charger-location`);
    return response.data;
  } catch (error) {
    console.error('Error fetching charger location:', error);
    throw error;
  }
}


export const generateSerialNumberAPI = async () => {  
  ensureAuthToken();
  try {
    const response = await axiosInstance.get(`serial-number/generate`);
    return response.data;
  } catch (error) {
    console.error(`Error generating serial no. :`, error);
    throw error;
  }
};

export const fetchOcppLogsAPI = async (chargerId, queryParams) => {  
  ensureAuthToken();
  try {
    const response = await axiosInstance.get(`/charger/${chargerId}/get-all-ocpp-logs`, {params: queryParams} );
    return response.data;
  } catch (error) {
    console.error(`Error fetching ocpp logs`, error);
    throw error;
  }
};

export const activateChargerAPI = async (queryParams) => {  
  ensureAuthToken();
  try {
    const response = await axiosInstance.patch(`/charger/activate`, queryParams );
    return response.data;
  } catch (error) {
    console.error(`Error activating charger`, error);
    throw error;
  }
};


export const getTransactions = async (queryParams) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.get(`/transaction`, { params: queryParams });
    return response.data;
  } catch (error) {
    console.error(`Error fetching transactions for:`, error);
    throw error;
  }
};

export const getSessions = async (txnId) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.get(`/transaction/${txnId}/session-list`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching sessions for:`, error);
    throw error;
  }
}

export const getSessionsList = async (chargeboxId, queryParams) => {  
  ensureAuthToken();
  try {
    const response = await axiosInstance.get(`/transaction/${chargeboxId}/session-list-by-charger`, { params: queryParams });
    return response.data;
  } catch (error) {
    console.error(`Error fetching sessions for:`, error);
    throw error;
  }
}

export const getCountries = async (queryParams) => {  
  ensureAuthToken();
  try {
    const response = await axiosInstance.get(`/country`, { params: queryParams });
    return response.data;
  } catch (error) {
    console.error(`Error fetching sessions for:`, error);
    throw error;
  }
}

// Export axiosInstance for custom configurations if needed
export default axiosInstance;

