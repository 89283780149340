import React, { useEffect, useState } from "react";
import { TextField, Box, CircularProgress, Autocomplete } from "@mui/material";
import { getCountries } from "../../redux/apis/chargers";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import Flag from "react-world-flags"; // Import the library
import { useSelector } from "react-redux";

const PhoneNumberInput = ({
    phoneNumber,
    setPhoneNumber,
    errors,
    defaultCountryCode,
}) => {
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [loading, setLoading] = useState(true);
    const selectedCountryFFromStore = useSelector((state) => state.country.countryName) || "";

    useEffect(() => {
        const fetchCountries = async () => {
            setLoading(true);
            try {
                const response = await getCountries({ limit: 1000 });
                const countryOptions = response.list.map((country) => ({
                    isoCode: country.isoCode,
                    phoneCode: country.phoneCode,
                    name: country.name,
                }));

                // Sort countries by name
                countryOptions.sort((a, b) => a.name.localeCompare(b.name));

                setCountries(countryOptions);

                // Set the default country based on `defaultCountryCode`
                const defaultCountry = countryOptions.find(
                    (country) => country.isoCode === defaultCountryCode
                );
                setSelectedCountry(defaultCountry || countryOptions[0]);
            } catch (error) {
                console.error("Failed to fetch countries:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCountries();
    }, [defaultCountryCode]);

    useEffect(() => {
        // Update selectedCountry when selectedCountryFFromStore changes
        if (selectedCountryFFromStore && countries.length > 0) {
            const matchedCountry = countries.find(
                (country) => country.isoCode === selectedCountryFFromStore
            );
            if (matchedCountry) {
                setSelectedCountry(matchedCountry);
            }
        }
    }, [selectedCountryFFromStore, countries]);

    const handleCountryChange = (event, newValue) => {
        if (newValue) {
            setSelectedCountry(newValue);
            setPhoneNumber(""); // Reset phone number on country change
        }
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value.replace(/\s+/g, "");

        if (selectedCountry) {
            const countryCode = selectedCountry.phoneCode;
            const fullNumber = value.startsWith(countryCode)
                ? value
                : countryCode + value;

            const phoneNumber = parsePhoneNumberFromString(
                fullNumber,
                selectedCountry.isoCode
            );

            if (phoneNumber && phoneNumber.isValid()) {
                setPhoneNumber(phoneNumber.nationalNumber);
            } else {
                setPhoneNumber(value);
            }
        } else {
            setPhoneNumber(value);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 1.5,
                maxHeight: "56px",
            }}
        >
            <>
                <Box sx={{ width: "250px" }}>
                    {loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <Autocomplete
                            options={countries}
                            getOptionLabel={(option) =>
                                `${option.name} (${option.phoneCode})`
                            }
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Country Code"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                            renderOption={(props, option) => (
                                <li
                                    {...props}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                    }}
                                >
                                    <Flag
                                        code={option.isoCode} // React World Flags uses ISO Alpha-2 codes
                                        style={{
                                            width: "20px",
                                            height: "15px",
                                            borderRadius: "2px",
                                        }}
                                    />
                                    {option.name} ({option.phoneCode})
                                </li>
                            )}
                        />
                    )}
                </Box>

                <TextField
                    label="Phone Number"
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    fullWidth
                    margin="normal"
                    error={!!errors}
                    helperText={errors}
                    placeholder={
                        selectedCountry
                            ? `${selectedCountry.phoneCode} 1234567890`
                            : "Phone Number"
                    }
                    sx={{
                        height: "56px",
                    }}
                />
            </>
        </Box>
    );
};

export default PhoneNumberInput;
