// countrySlice.js

import { createSlice } from "@reduxjs/toolkit";


const countrySlice = createSlice({
  name: "country",
  initialState: {
    countryName: "",
  },
  reducers: {
    updateCountryName: (state, action) => {
      state.countryName = action.payload;
    },
  },
});

export const { updateCountryName } = countrySlice.actions;

export default countrySlice.reducer;
