// SettlementsPage.js
import React, { useState } from 'react';
import {
    Tabs,
    Tab,
    Box,
    Button,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import OverviewTab from './OverviewTab';
import ActivityTab from './ActivityTab'; // Placeholder for Activity Tab component
import SettlementsTab from './SettlementsTab'; // Placeholder for Settlements Tab component
import MainLayout from '../../layout/mainLayout';
import ModalComponent from '../../components/atoms/ModalComponent';
import RetryIcon from '@mui/icons-material/Replay';

const SettlementsPage = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [isBankAccountModalOpen, setBankAccountModalOpen] = useState(false);
    const [isSettlementScheduleModalOpen, setSettlementScheduleModalOpen] = useState(false);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };
    const onRetry = () => {

    }
    const settlementId = "SET12345";
    const failureReason = "Failed due to bank server issues."

    return (
        <MainLayout>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 3,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: 3,  // Reduced gap for a more compact look
                        alignItems: 'center',
                        padding: '15px',  // Slightly more padding for better spacing
                        backgroundColor: '#FF8A80',  // Softer, more appealing background color
                        color: 'white',
                        borderRadius: '8px',  // Slightly rounded corners for a modern touch
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',  // Add subtle shadow for depth
                        marginBottom: '15px',
                    }}
                >
                    <Box>
                        <Typography variant="body1" fontWeight="bold">
                            Settlement ID: {settlementId}
                        </Typography>
                        <Typography variant="body2">{failureReason}</Typography>
                    </Box>

                    <Button
                        variant="contained"
                        onClick={onRetry}
                        sx={{
                            backgroundColor: '#FF5252',  // Use a red color that stands out
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#FF1744',  // Darker shade for hover effect
                                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',  // Subtle shadow on hover for a pressed effect
                            },
                            borderRadius: '8px',  // Rounded button for consistency
                            padding: '6px 16px',  // Padding adjustment for a more button-like look
                        }}
                        startIcon={<RetryIcon sx={{ color: 'white' }} />}  // Retry icon in white
                    >
                        Retry
                    </Button>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setBankAccountModalOpen(true)}
                        sx={{ marginRight: 2, color: '#FFFFFF' }}
                    >
                        Manage your Bank Account
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setSettlementScheduleModalOpen(true)}
                    >
                        Manage your Settlement Schedule
                    </Button>
                </Box>
            </Box>
            <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                <Tab label="Overview" />
                <Tab label="Activity" />
                <Tab label="Settlements" />
            </Tabs>

            <div style={{ marginTop: '20px' }}>
                {activeTab === 0 && <OverviewTab />}
                {activeTab === 1 && <ActivityTab />}
                {activeTab === 2 && <SettlementsTab />}
            </div>
            {/* Modal for Bank Account */}
            {/* Modal for Bank Account Management */}
            <ModalComponent
                open={isBankAccountModalOpen}
                handleClose={() => setBankAccountModalOpen(false)}
                headerText="Manage Your Bank Account"
                showSubmitButton={true}
                submitButtonLabel="Add Bank Account"
            >
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Linked Bank Accounts
                </Typography>
                <List>
                    <ListItem
                        sx={{
                            border: "1px solid",
                            borderColor: "divider",
                            borderRadius: 2,
                            padding: 2,
                            marginBottom: 2
                        }}
                    >
                        <ListItemText
                            primary="Bank of Montreal (CAD) - **** **** **** 9874"
                            secondary="Last Updated: 01/12/2024"
                        />
                        <Button
                            size="small"
                            color="primary"
                            variant="outlined"
                            sx={{ textTransform: "capitalize" }}
                        >
                            Edit
                        </Button>
                    </ListItem>
                </List>
            </ModalComponent>

            {/* Modal for Settlement Schedule */}
            <ModalComponent
                open={isSettlementScheduleModalOpen}
                handleClose={() => setSettlementScheduleModalOpen(false)}
                showSubmitButton={false}
                headerText="Manage Your Settlement Schedule"
            >
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Settlement Schedule
                </Typography>
                <Typography variant="body1" color="textSecondary" marginBottom={3}>
                    Set a schedule to automatically move revenue from your payments balance to your bank account.
                </Typography>
                <RadioGroup name="schedule" sx={{ marginTop: 2 }}>
                    <FormControlLabel
                        value="15days"
                        control={<Radio color="primary" />}
                        label={
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Typography variant="body1" fontWeight="bold" marginRight={1}>
                                    Every 15 Days
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Recommended for frequent transactions
                                </Typography>
                            </Box>
                        }
                    />
                    <FormControlLabel
                        value="30days"
                        control={<Radio color="primary" />}
                        label={
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Typography variant="body1" fontWeight="bold" marginRight={1}>
                                    Every 30 Days
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Ideal for monthly settlements
                                </Typography>
                            </Box>
                        }
                    />
                </RadioGroup>
                <Box textAlign="center" marginTop={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => alert("Save schedule functionality here")}
                    >
                        Save Schedule
                    </Button>
                </Box>
            </ModalComponent>
        </MainLayout>
    );
};

export default SettlementsPage;
