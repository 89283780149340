import React from "react";
import { Card } from "../atoms/card";
import {
  Typography,
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Paper,
  IconButton,
  Box,
  CircularProgress
} from "@mui/material";
import Pusher from "pusher-js";
import { useState, useEffect } from "react";
import { fetchEvseStationsAPI } from "../../redux/apis/evseStations";
import { fetchChargerList } from "../../redux/apis/chargers";
import { invokeAPI } from "../../redux/apis/rapid";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";

function KernelLog() {
  const [kernelLogs, setKernelLogs] = useState([]);
  const selectedChargerFromRedux = useSelector((state) => state.filter.charger);
  const selectedEvseFromRedux = useSelector((state) => state.filter.evse);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setKernelLogs([]);
  }, [selectedEvseFromRedux === "" && selectedChargerFromRedux === ""]);

  // Fetch Kernel Logs data when a device is selected
  useEffect(() => {
    const fetchKernelLogsData = async () => {

      try {
        if (selectedChargerFromRedux) {
          await invokeAPI({
            deviceId: selectedChargerFromRedux.serialNumber,
            reset: false,
            type: "LIVE_KERNAL_LOGS",
          });
        }
      } catch (error) {
        console.error("Error fetching Kernel Logs:", error);
      }

    };
    fetchKernelLogsData();
  }, [selectedChargerFromRedux]);


  // Download kernel logs as a text file
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob(kernelLogs.map((ele) => `${ele}\n`), {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "kernelLogs.txt";
    document.body.appendChild(element);
    element.click();
  };

  const bindWithChunking = (channel, event, callback) => {
    let events = {};
    channel.bind(event, (data) => {
      if (!events.hasOwnProperty(data.id)) {
        events[data.id] = { chunks: [], receivedFinal: false };
      }
      let ev = events[data.id];
      ev.chunks[data.index] = data.chunk;
      if (data.final) ev.receivedFinal = true;
      if (
        ev.receivedFinal &&
        ev.chunks.length === Object.keys(ev.chunks).length
      ) {
        try {
          // Parse only `kernelLogs` attribute and split by `\n`
          const fullData = JSON.parse(ev.chunks.join(""));
          const kernelLogsArray = fullData.kernalLogs.split("\n");
          callback(kernelLogsArray);
        } catch (err) {
          console.error("Error parsing JSON data:", err);
        }
        delete events[data.id];
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    if (selectedChargerFromRedux) {

      const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      });
      const channel = pusher.subscribe(selectedChargerFromRedux.serialNumber);
      bindWithChunking(channel, 'CHUNKED_LIVE_KERNAL_LOG', (logsArray) => {
        
        setKernelLogs(logsArray);
        setLoading(false);
      });

      return () => {
        setLoading(false);
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [selectedChargerFromRedux]);


  return (
    <>

      {
        loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box >
        ) :
          <Card xs={12} md={12}>
            {kernelLogs.length > 0 && (
              <div
                style={{
                  fontWeight: "bold",
                  width: "100px",
                  margin: "10px 20px 10px 20px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={downloadTxtFile}
                  style={{
                    textTransform: "none",
                    backgroundColor: "#0fcbd0",
                    color: "white",
                    borderRadius: "10px",
                    height: "25px",
                    padding: "10px",
                  }}
                >
                  Download
                </Button>
              </div>
            )}

            <div style={{ padding: "20px" }}>
              <Paper
                sx={{
                  width: "98%",
                  overflow: "auto",
                  minHeight: "60vh",
                  maxHeight: "70vh",
                  padding: "10px",
                }}
              >
                {kernelLogs.length === 0
                  ? "No Logs available"
                  : loading ? <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress /></Box> : kernelLogs.map((log, index) => (
                    <Typography key={index}>{log}</Typography>
                  ))}
              </Paper>
            </div>

          </Card>
      }

    </>
  );
}

export default KernelLog;
