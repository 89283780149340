import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Radio,
  Tooltip,
  LinearProgress
} from "@mui/material";
import TableRowsIcon from '@mui/icons-material/TableRows';
import { Add, Edit, Search, Delete, PlayArrow, Refresh, CopyAll } from "@mui/icons-material";
import ButtonComponent from "../../components/atoms/button/button";
import { fetchChargerList } from "../../redux/apis/chargers";
import MainLayout from "../../layout/mainLayout";
import ModalComponent from "../../components/atoms/ModalComponent";
import { fetchEvseStationsAPI } from "../../redux/apis/evseStations";
import { createRollout, fetchRollouts, uploadFile, deleteRollout, editRollout, playRapidRollout, getRolloutStats, copyRollout, getRolloutDetails, getRolloutLogs, getRolloutDetailStats } from "../../redux/apis/rapid";
import Pusher from "pusher-js";
import FileUpload from "../../components/file-uploader/upload";
import { message } from "antd";

const RollOut = () => {
  const [openCreateModal, setCreateOpen] = useState(false);
  const [openEditModal, setEditOpen] = useState(false);
  const [openDeleteModal, setDeleteOpen] = useState(false);
  const [currentRollOut, setCurrentRollOut] = useState(null);
  const [fileUploadData, setFileUploadData] = useState(null);
  const [aritifactName, setAritifactName] = useState("");
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [addRolloutData, setAddRolloutData] = useState({
    name: "",
    description: "",
    group: null,
    type: "",
    artifact: "",
    deviceIds: [], // Initialize deviceIds as an array
  });
  const [loadPercentage, setLoadPercentage] = useState(-1);
  const [rollOutData, setRollOutData] = useState([]);
  const [EvseStations, setEvseStationsData] = useState([]);
  // const [groupList, setGroupList] = useState([]);
  const [chargers, setChargers] = useState([]);
  // const [chargers, setChargers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pusherData, SetPusherData] = useState(null);
  const [showDetailedModal, setShowDetailedModal] = useState(false);
  const [selectedRollout, setSelectedRollout] = useState(null);
  const [detailedStatus, setDetailedStatus] = useState({
    applied: 0,
    completion: 0,
    downloaded: 0,
    extracted: 0,
  });
  const [loading, setLoading] = useState(false);
  const [rolloutStats, setRolloutStats] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  //Rollout Detials
  const [rolloutDetails, setRolloutDetails] = useState({
    list: [],
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
  });

  const [rolloutDetailspage, setRolloutDetailsPage] = useState(1);
  const [openRolloutDetails, setOpenRolloutDetails] = useState(false);

  const fetchRolloutDetails = async (rolloutId, page = 1) => {
    setLoading(true);
    try {
      const response = await getRolloutDetails(rolloutId);
      // Assuming response contains pagination data
      setRolloutDetails({
        list: response.list,
        currentPage: response.currentPage,
        totalPages: response.totalPages,
        totalCount: response.totalCount,
      });
    } catch (error) {
      console.error('Error fetching rollout details:', error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleRolloutDetails = (rollout) => {
    fetchRolloutDetails(rollout._id, rolloutDetailspage);
    setOpenRolloutDetails(true);
  };

  const handleCloseRolloutDetails = () => {
    setOpenRolloutDetails(false);
  };

  const handleRolloutDetailsPageChange = (event, value) => {
    setRolloutDetailsPage(value);
    fetchRolloutDetails(rolloutDetails.list[0].rollout, value); // Fetch new page data
  };

  //RolloutLogs

  const [openRolloutLogs, setOpenRolloutLogs] = useState(false);
  const [RolloutLogsData, setRolloutLogs] = useState([])
  const handleCloseRolloutLogs = () => {
    setOpenRolloutLogs(false);
  }
  const fetchRolloutLogs = async (rolloutId, devicdeId) => {
    setLoading(true);
    try {
      const response = await getRolloutLogs(rolloutId, devicdeId);
      setRolloutLogs(response);
    } catch (error) {
      console.error('Error fetching rollout logs:', error);
    }
    finally {
      setLoading(false);
    }
  }
  const handleRolloutLogs = (rolloutId, devicdeId) => {
    setOpenRolloutLogs(true);
    fetchRolloutLogs(rolloutId, devicdeId);
  };



  // Progress bar code
  const renderProgressBar = (value, status) => {
  const isLoading = status !== "CREATED" && status !== "FAILED";

  return (
    <Box position="relative" display="inline-flex" width="100%">
      <LinearProgress
        variant={isLoading ? "indeterminate" : "determinate"}
        value={isLoading ? undefined : value || 0}
        sx={{
          height: 20,
          borderRadius: 5,
          cursor: 'pointer',
          width: '100%',
          backgroundColor: status === 'COMPLETED' ? '#e0e0e0' : '#005AAD',
          '& .MuiLinearProgress-bar': {
            backgroundColor: isLoading ? 'linear-gradient(90deg, #2196F3, #21CBF3)' : '#006ccc',
            animation: isLoading ? 'loadingAnimation 1.5s infinite ease-in-out' : undefined,
          },
        }}
      />
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            position: 'absolute',
            cursor: 'pointer',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: '12px',
            fontWeight: 'bold',
            color: isLoading ? '#FFFFFF' : '#000000',
          }}
        >
          {`${Math.round(value)}%`}
        </Typography>
    </Box>
  );
};
  useEffect(() => {
    // Get the current user ID from localStorage or sessionStorage
    const currentUser = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
    if (!currentUser) {
      console.error("User ID not found");
      return;
    }

    console.log("Inside Pusher Initialization");

    // Initialize Pusher
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    // Subscribe to the user's channel using the user ID
    const channel = pusher.subscribe(currentUser);

    // Handler for ROLLOUT_STATUS_UPDATE event
    const handleRolloutDetailsStatusUpdate = (data) => {
      console.log("Rollout status update:", data);

      const rolloutDetails = data.deviceState;

      if (rolloutDetails) {
        setRolloutDetails((prevData) => ({
          ...prevData, // Keep other properties like currentPage, totalPages, etc.
          list: prevData.list.map((rollOut) =>
            rollOut._id === rolloutDetails._id
              ? {
                ...rollOut,
                isDownloaded: rolloutDetails?.isDownloaded,
                isExtracted: rolloutDetails?.isExtracted,
                isApplied: rolloutDetails?.isApplied,
              }
              : rollOut
          ),
        }));
        
      }

      const updatedRollout = data.rollout;

      // Update only the completion and additional attributes of the existing rollout
      if (updatedRollout) {
        setRollOutData((prevData) =>
          prevData.map((rollOut) =>
            rollOut._id === updatedRollout._id
              ? {
                ...rollOut,
                completion: updatedRollout.completion,
              }
              : rollOut
          )
        );
      }
    };


    // Bind to the events
    channel.bind("ROLLOUT_DEVICE_STATUS_UPDATE", handleRolloutDetailsStatusUpdate);

    // Cleanup function
    return () => {
      channel.bind("ROLLOUT_DEVICE_STATUS_UPDATE", handleRolloutDetailsStatusUpdate);


      pusher.unsubscribe(currentUser);
      pusher.disconnect();
    };
  }, []);


  const fetchRolloutDetailStatsData = async (rolloutId) => {
    try {
      const response = await getRolloutDetailStats(rolloutId);
      setDetailedStatus(response);
    } catch (error) {
      console.error("Error fetching rollout details:", error);
    }
  };

  const fetchStationsData = async () => {
    try {
      const response = await fetchEvseStationsAPI({ limit: 500 });
      const allSites = response.list || [];
      setEvseStationsData(allSites);
    } catch (error) {
      console.error("Error fetching sites:", error);
    }
  };

  useEffect(() => {
    fetchStationsData();
  }, []);

  const fetchChargerData = async () => {
    if (addRolloutData.EvseStationId) {
      const response = await fetchChargerList({
        filter: JSON.stringify({ EvseStationId: addRolloutData.EvseStationId }),
      });
      setChargers(response.list || []);
    }
  };

  useEffect(() => {
    fetchChargerData();
  }, [addRolloutData.EvseStationId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAddRolloutData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const serialNumber = value;

    setAddRolloutData((prevData) => ({
      ...prevData,
      deviceIds: checked
        ? [...prevData.deviceIds, serialNumber] // Add serialNumber if checked
        : prevData.deviceIds.filter((id) => id !== serialNumber), // Remove chargeBoxId if unchecked
    }));
  };



  const handleUpdateTypeChange = (event) => {
    setAddRolloutData((prevState) => ({
      ...prevState,
      type: event.target.value,
    }));
  };



  const handleOpenCreateModal = () => {
    setAddRolloutData({
      name: "",
      group: null,
      description: "",
      deviceIds: [],
      artifact: "",
      type: "", // Reset update type
    });
    setCreateOpen(true);
  };

  const handleOpenEditModal = (rollOut) => {
    let fileName;
    if (rollOut.artifact) {
      fileName = new URL(rollOut.artifact).pathname.substring(1);
    }
    setAritifactName(fileName);

    setSelectedRollout(rollOut);

    setAddRolloutData({
      name: rollOut.name,
      description: rollOut.description,
      group: rollOut.group,
      artifact: rollOut.artifact,
      deviceIds: Array.isArray(rollOut.deviceIds) ? rollOut.deviceIds : [],
      type: rollOut.type,
    });
    setFileUploadData(null);
    setEditOpen(true);
  };



  const handleEditSubmit = () => {
    if (loading) {
      message.error("Please Wait While Artifact Is Being Uploaded");
      return
    }

    try {
      editRollout(selectedRollout._id, addRolloutData).then(() => {
        setEditOpen(false);
        message.success("Rollout Updated Successfully");
        setSelectedRollout(null);

        // Clear the artifact name
        setAritifactName("");

        // Reset the form data for adding or editing rollouts
        setAddRolloutData({
          name: "",
          description: "",
          group: "",
          artifact: "",
          deviceIds: [],
          type: "",
        });

        // Clear the file upload data
        setFileUploadData(null);
        fetchRolloutsData();
      }).catch((error) => {
        message.error("Error Updating Rollout");
        console.error("Error updating rollout:", error);
      });
    } catch (error) {
      message.error("Error Updating Rollout");
      console.error("Error updating rollout:", error);
      setEditOpen(false);
    }
  };

  const handleCreateSubmit = async () => {

    if (loading) {
      message.error("Please Wait While Artifact Is Being Uploaded");
      return
    }

    const createData = {
      ...addRolloutData
    }

    try {

      const response = await createRollout(createData);
      if (response) {
        setAddRolloutData({
          name: "",
          description: "",
          group: "",
          artifact: "",
          deviceIds: [],
          type: "",
        });
        setCreateOpen(false);
        message.success("Rollout Created Successfully");
        fetchRolloutsData();
      }
    }
    catch (error) {
      message.error("Error Creating Rollout");
      console.error("Error creating rollout:", error);
      setCreateOpen(false);
    }
  };

  const handleCloseCreateModal = () => {
    setAddRolloutData({
      name: "",
      description: "",
      group: "",
      artifact: "",
      deviceIds: [],
      type: "", // Reset update type
    })
    setCreateOpen(false);
    setChargers([]);

    setChargers([]);
  };


  const handleCloseEditModal = () => {
    // Reset the selected rollout data
    setSelectedRollout(null);

    // Clear the artifact name
    setAritifactName("");

    // Reset the form data for adding or editing rollouts
    setAddRolloutData({
      name: "",
      description: "",
      group: "",
      artifact: "",
      deviceIds: [],
      type: "",
    });

    // Clear the file upload data
    setFileUploadData(null);

    // Close the edit modal
    setEditOpen(false);

    // Set chargers List to empty
    setChargers([]);
  };


  const handleDelete = (rollout) => {
    console.log(rollout)
    setSelectedRollout(rollout);
    setDeleteOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await deleteRollout(selectedRollout._id);
      if (response) {
        setAddRolloutData({
          name: "",
          description: "",
          group: "",
          artifact: "",
          deviceIds: [],
          type: "", // Reset update type
        })
        fetchRolloutsData();
        setDeleteOpen(false);
        message.success("Rollout Deleted Successfully");
      }
    } catch (error) {
      setDeleteOpen(false);
      message.error("Error Deleting Rollout");
      console.error("Error deleting rollout:", error);
    }

  };

  const cancelDelete = () => {
    setDeleteOpen(false);
  };

  const handleCopy = async (rollout) => {
    try {
      const response = await copyRollout(rollout._id);
      if (!response.data.error) {
        fetchRolloutsData();
        message.success("Rollout Copied Successfully");
      } else {
        message.error(response.error);
      }

    }
    catch (error) {
      message.error("Error Copying Rollout");
      console.error("Error copying rollout:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const [chargerpage, setChargerPage] = useState(0);
  const [chargerRowsPerPage, setChargerRowsPerPage] = useState(5);
  const [selectAll, setSelectAll] = useState(false);
  const handleChargerPageChange = (event, newPage) => {
    setChargerPage(newPage);
  };

  const handleChargerRowsPerPageChange = (event) => {
    setChargerRowsPerPage(parseInt(event.target.value, 10));
    setChargerPage(0);
  };

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    const newSelected = event.target.checked
      ? chargers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(device => device.serialNumber)
      : [];
    setAddRolloutData((prevState) => ({
      ...prevState,
      deviceIds: newSelected,
    }));
  };


  const fetchRolloutsData = async () => {
    setLoading(true);
    try {
      const response = await fetchRollouts({
        search: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
      });
      setRollOutData(response.list);
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRolloutsData();
  }, [searchQuery, page, rowsPerPage]);

  //File upload
  useEffect(() => {
    const formData = new FormData();
    if (fileUploadData === null) {
      setAddRolloutData((prevVal) => ({ ...prevVal, artifact: null }));
      setAritifactName("");
    }
    if (fileUploadData?.size) {
      formData.append("upload", fileUploadData);

      setLoading(true)
      uploadFile(formData, setLoadPercentage)
        .then((res) => {
          if (!res.message) {
            setAddRolloutData((prevVal) => {
              const updatedData = { ...prevVal, artifact: res.location };
              return updatedData;
            });
            setAritifactName(res.key);
          } else {
            message.error("Failed To Upload, Try Again!");
            setFileUploadData(null);
            setAritifactName("");
            setIsFileUploading(false);
            setAddRolloutData((prevVal) => ({ ...prevVal, artifact: null }));
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Failed To Upload, Try Again!");
          setFileUploadData(null);
          setAritifactName("");
          setIsFileUploading(false);
          setAddRolloutData((prevVal) => ({ ...prevVal, artifact: null }));
        }).finally(() => {
          setLoading(false)
        });
    }
  }, [fileUploadData]);

  // const fetchgroupsData = async () => {
  //   try {
  //     const response = await groupDropdown();
  //     if (response) {
  //       let data = response.map((iter) => {
  //         return { value: iter.id, name: iter.name };
  //       });
  //       setGroupList(data);
  //     } else {
  //       console.log("Fetching group list failed");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const fetchchargersData = async () => {
    try {
      if (addRolloutData.group) {
        const response = await fetchChargerList(
          {
            filter: `{"EvseStationId": "${addRolloutData.group}"}`,
          }
        );
        setChargers(response.list);
      }
    } catch (error) {
    }
  };


  useEffect(() => {
    fetchchargersData();
  }, [addRolloutData.group]);

  //Play Rollout

  const playRollout = async (rollOutId) => {
    try {
      const response = await playRapidRollout(rollOutId);

      if (response) {
        message.success("Rollout Started");
        fetchRolloutsData();

      } else {
        console.log("Failed to play rollout");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Fetching rollout Stats

  const fetchRolloutStats = async (selectedRollout) => {
    try {
      const response = await getRolloutStats(selectedRollout._id);
      setRolloutStats(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDetailsModal = (rollout) => {
    setSelectedRollout(rollout);
    fetchRolloutDetailStatsData(rollout._id);
    setShowDetailedModal(true);
  };

  useEffect(() => {
    fetchRolloutStats(selectedRollout);
    console.log(rolloutStats);
  }, [selectedRollout]);



  return (
    <MainLayout>
      <div>
        {/* Search Filter and Add/Create Button */}
        <Typography
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"0rem 0rem 1rem 0rem"}
        >
          <Typography>
            <TextField
              id="s"
              variant="outlined"
              hiddenLabel
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
          </Typography>
          <Typography>
            <ButtonComponent
              label={
                <>
                  <Add />{" "}
                  <Typography component={"span"}>Create Rollout</Typography>
                </>
              }
              width={"200px"}
              height={"40px"}
              onClick={handleOpenCreateModal}
            />
          </Typography>
        </Typography>

        {/* Create Modal */}
        <ModalComponent
          open={openCreateModal}
          handleClose={handleCloseCreateModal}
          headerText="Create Rollout"
          handleSubmit={handleCreateSubmit}
          submitButtonLabel="Save"
        >
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <TextField
                label="Rollout Name"
                name="name"
                value={addRolloutData?.name}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                value={addRolloutData?.description}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={8} md={8}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography ><strong>
                  Upload Artifacts</strong>
                </Typography>
                <div>
                  <FileUpload
                    value={aritifactName}
                    setIsFileUploading={setIsFileUploading}
                    callBack={setFileUploadData}
                  />
                </div>
                {loading ? (
                  <CircularProgress size={20} />
                ) : ""}
                <Typography variant="body2"><b>{"Note:"}</b>{" Please upload tar.gz file"}</Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Group</InputLabel>
                <Select
                  name="group"
                  value={addRolloutData?.group}
                  onChange={handleChange}
                  label="Group"
                >
                  {EvseStations.map((group) => (
                    <MenuItem key={group?.id} value={group?.id}>
                      {group?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography><strong>Update Type:</strong></Typography>
              <FormControl
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                }}
              >
                <FormControlLabel
                  value="OS"
                  control={<Radio />}
                  label="OS"
                  checked={addRolloutData.type === "OS"}
                  onChange={handleUpdateTypeChange}
                />
                <FormControlLabel
                  value="Application"
                  control={<Radio />}
                  label="Application"
                  checked={addRolloutData.type === "Application"}
                  onChange={handleUpdateTypeChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography ><strong>Select chargers:</strong></Typography>
              <TableContainer
                sx={{
                  maxHeight: "55vh",
                  overflowX: "hidden",
                  overflowY: "auto",
                  borderRadius: "8px 8px 0 0",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                }}
              >
                <Table>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 99,
                      "& th": {
                        border: "1px solid #e0e0e0",
                        background: "#F5F3F3",
                        fontWeight: "500",
                        color: "#333",
                      },
                      boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                    }}>
                    <TableRow>
                      <TableCell align="center"><Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />Select All</TableCell>
                      <TableCell align="center">Sr. No</TableCell>
                      <TableCell align="center">Charge Box Id</TableCell>
                      <TableCell align="center">Evse Station</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Reg. Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      "& td": {
                        border: "1px solid #e0e0e0",
                        color: "#555",
                      },
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: "#fafafa",
                      },
                    }}
                  >
                    {chargers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((charger) => (
                      <TableRow key={charger.serialNumber}>
                        <TableCell align="center">
                          <FormControlLabel
                            key={charger.id}
                            control={
                              <Checkbox
                                value={charger.serialNumber}
                                checked={Array.isArray(addRolloutData.deviceIds) && addRolloutData.deviceIds.includes(charger.serialNumber)}
                                onChange={handleCheckboxChange}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell align="center">{charger.serialNumber}</TableCell>
                        <TableCell align="center">{charger.chargeBoxId}</TableCell>
                        <TableCell align="center">{charger.evseStationName}</TableCell>
                        <TableCell align="center">{charger.status}</TableCell>
                        <TableCell align="center">{new Date(charger.registeredAt).toLocaleDateString()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    overflowX: "hidden",
                    padding: "0 0.5rem",
                    background: "#f5f3f3",
                    borderTop: "1px solid #e0e0e0",
                    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "0 0 8px 8px",
                  }}
                  count={chargers.length}
                  rowsPerPage={chargerRowsPerPage}
                  page={chargerpage}
                  onPageChange={handleChargerPageChange}
                  onRowsPerPageChange={handleChargerRowsPerPageChange}
                />
              </TableContainer>
            </Grid>
          </Grid>
        </ModalComponent>

        <ModalComponent
          open={openEditModal}
          handleClose={handleCloseEditModal}
          headerText="Edit Rollout"
          handleSubmit={handleEditSubmit}
          submitButtonLabel="Save"
        >
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <TextField
                label="Rollout Name"
                name="name"
                value={addRolloutData.name}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                value={addRolloutData.description}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={8} md={8}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography ><strong>
                  Upload Artifacts</strong>
                </Typography>
                <div>
                  <FileUpload
                    value={aritifactName}
                    setIsFileUploading={setIsFileUploading}
                    callBack={setFileUploadData}
                  />
                </div>
                {loading ? (
                  <CircularProgress size={20} />
                ) : ""}
                <Typography variant="body2"><b>{"Note:"}</b>{" Please upload tar.gz file"}</Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Group</InputLabel>
                <Select
                  name="group"
                  value={addRolloutData.group}
                  onChange={handleChange}
                  label="Group"
                >
                  {EvseStations.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography><strong>Update Type:</strong></Typography>
              <FormControl
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                }}
              >
                <FormControlLabel
                  value="OS"
                  control={<Radio />}
                  label="OS"
                  checked={addRolloutData.type === "OS"}
                  onChange={handleUpdateTypeChange}
                />
                <FormControlLabel
                  value="Application"
                  control={<Radio />}
                  label="Application"
                  checked={addRolloutData.type === "Application"}
                  onChange={handleUpdateTypeChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography ><strong>Select Chargers:</strong></Typography>
              <TableContainer
                sx={{
                  maxHeight: "55vh",
                  overflowX: "hidden",
                  overflowY: "auto",
                  borderRadius: "8px 8px 0 0",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                }}
              >
                <Table>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 99,
                      "& th": {
                        border: "1px solid #e0e0e0",
                        background: "#F5F3F3",
                        fontWeight: "500",
                        color: "#333",
                      },
                      boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <TableRow>
                      <TableCell align="center">
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />Select All</TableCell>
                      <TableCell align="center">Sr. No</TableCell>
                      <TableCell align="center">Charge Box Id</TableCell>
                      <TableCell align="center">Evse Station</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Reg. Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      "& td": {
                        border: "1px solid #e0e0e0",
                        color: "#555",
                      },
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: "#fafafa",
                      },

                    }}
                  >
                    {chargers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((charger) => (
                      <TableRow key={charger.id}>
                        <TableCell align="center">
                          <FormControlLabel
                            key={charger.id}
                            control={
                              <Checkbox
                                value={charger.serialNumber}
                                checked={Array.isArray(addRolloutData.deviceIds) && addRolloutData.deviceIds.includes(charger.serialNumber)}
                                onChange={handleCheckboxChange}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell align="center">{charger.serialNumber}</TableCell>
                        <TableCell align="center">{charger.chargeBoxId}</TableCell>
                        <TableCell align="center">{charger.evseStationName}</TableCell>
                        <TableCell align="center">{charger.status}</TableCell>
                        <TableCell align="center">{new Date(charger.registeredAt).toLocaleDateString()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    overflowX: "hidden",
                    padding: "0 0.5rem",
                    background: "#f5f3f3",
                    borderTop: "1px solid #e0e0e0",
                    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "0 0 8px 8px",
                  }}
                  count={chargers.length}
                  rowsPerPage={chargerRowsPerPage}
                  page={chargerpage}
                  onPageChange={handleChargerPageChange}
                  onRowsPerPageChange={handleChargerRowsPerPageChange}
                />
              </TableContainer>
            </Grid>
          </Grid>
        </ModalComponent>

        {/* Details Status Modal */}

        <ModalComponent
          open={showDetailedModal}
          handleClose={() => setShowDetailedModal(false)}
          headerText="Details Status"
          showSubmitButton={false}
        >
          <div>
            <div>
              <Box
                sx={{
                  padding: "16px",
                  border: "1px solid #e0e0e0", // Light grey border
                  borderRadius: "12px", // Slightly rounded corners
                  backgroundColor: "#fff", // White background
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for a lifted look
                  maxWidth: "600px", // Adjust as per your layout
                  margin: "auto", // Center horizontally
                }}
              >
                <Grid container md={12} xs={12} style={{ justifyContent: "center" }}>
                  <Grid item md={3} sm={6} xs={8} style={{ display: "flex", justifyContent: "flex-start" }}>
                    <p style={{ marginBottom: "0px", fontWeight: "bold", color: "#424242" }}>Applied</p>
                  </Grid>
                  <Grid item md={3} xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <p style={{ marginBottom: "0px", color: "#616161" }}>{detailedStatus.applied}</p>
                  </Grid>
                </Grid>

                <Grid container md={12} xs={12} style={{ justifyContent: "center", marginTop: "8px" }}>
                  <Grid item md={3} sm={6} xs={8} style={{ display: "flex", justifyContent: "flex-start" }}>
                    <p style={{ marginBottom: "0px", fontWeight: "bold", color: "#424242" }}>Completion</p>
                  </Grid>
                  <Grid item md={3} xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <p style={{ marginBottom: "0px", color: "#616161" }}>{detailedStatus.completion}%</p>
                  </Grid>
                </Grid>

                <Grid container md={12} xs={12} style={{ justifyContent: "center", marginTop: "8px" }}>
                  <Grid item md={3} sm={6} xs={8} style={{ display: "flex", justifyContent: "flex-start" }}>
                    <p style={{ marginBottom: "0px", fontWeight: "bold", color: "#424242" }}>Downloaded</p>
                  </Grid>
                  <Grid item md={3} xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <p style={{ marginBottom: "0px", color: "#616161" }}>{detailedStatus.downloaded}</p>
                  </Grid>
                </Grid>

                <Grid container md={12} xs={12} style={{ justifyContent: "center", marginTop: "8px" }}>
                  <Grid item md={3} sm={6} xs={8} style={{ display: "flex", justifyContent: "flex-start" }}>
                    <p style={{ marginBottom: "0px", fontWeight: "bold", color: "#424242" }}>Extracted</p>
                  </Grid>
                  <Grid item md={3} xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <p style={{ marginBottom: "0px", color: "#616161" }}>{detailedStatus.extracted}</p>
                  </Grid>
                </Grid>
              </Box>
            </div>

          </div>
        </ModalComponent>

        {/* Delete Confirmation Modal */}
        <Dialog
          open={openDeleteModal}
          onClose={() => setDeleteOpen(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this rollout?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDelete}>Cancel</Button>
            <Button onClick={confirmDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Rollouts Table */}
        {
          loading ? <div style={{ height: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div> :
            <>
              <TableContainer
                sx={{
                  maxHeight: "55vh",
                  overflowX: "hidden",
                  overflowY: "auto",
                  borderRadius: "8px 8px 0 0",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                }}
              >
                <Table>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 99,
                      "& th": {
                        border: "1px solid #e0e0e0",
                        background: "#F5F3F3",
                        fontWeight: "500",
                        color: "#333",
                      },
                      boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <TableRow sx={{
                      "& td, & th": { border: "1px solid #4C4E641F !important" },
                    }}>
                      <TableCell align="center">Sr. No</TableCell>
                      <TableCell align="center">Rollout Name</TableCell>
                      <TableCell align="center">Description</TableCell>
                      <TableCell align="center">Details Status</TableCell>
                      <TableCell align="center">Total chargers</TableCell>
                      <TableCell align="center">Created By</TableCell> {/* Added column */}
                      <TableCell align="center">Created Date</TableCell> {/* Added column */}
                      <TableCell align="center">Actions</TableCell> {/* Added column */}
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      "& td": {
                        border: "1px solid #e0e0e0",
                        color: "#555",
                      },
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: "#fafafa",
                      },

                    }}>
                    {rollOutData.map((rollOut, index) => (
                      <TableRow key={rollOut._id} sx={{
                        "& td, & th": { border: "1px solid #4C4E641F !important" },
                      }}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center" onClick={() => handleRolloutDetails(rollOut)} sx={{ cursor: "pointer", textDecoration: "underline" }}>{rollOut.name}</TableCell>
                        <TableCell align="center">{rollOut.description}</TableCell>
                        <TableCell align="center" onClick={() => handleDetailsModal(rollOut)}>
                          {renderProgressBar(rollOut.completion)}
                        </TableCell>
                        <TableCell align="center">{rollOut.deviceIds.length}</TableCell>
                        <TableCell align="center">{rollOut.createdByUser.firstName + " " + rollOut.createdByUser.lastName}</TableCell>
                        <TableCell align="center">{new Date(rollOut.createdAt).toLocaleString()}</TableCell>
                        <TableCell align="center">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {rollOut.status === "CREATED" ? (
                              <IconButton onClick={() => playRollout(rollOut._id)}>
                                <PlayArrow
                                  fontSize="large"
                                  style={{
                                    color: "#075a6a",
                                    cursor: "pointer",
                                  }}
                                />
                              </IconButton>
                            ) : rollOut.status === "FAILED" ? (
                              <IconButton onClick={() => playRollout(rollOut._id)}>
                                <Refresh
                                  fontSize="large"
                                  style={{
                                    color: "#28d1d5",
                                    cursor: "pointer",
                                  }}
                                />
                              </IconButton>
                            ) : (
                              <Tooltip title="Retry not allowed">
                                <span>
                                  <Refresh
                                    style={{
                                      color: "#a7afaf",
                                    }}
                                    fontSize="large"
                                    disabled
                                  />
                                </span>
                              </Tooltip>
                            )}

                            <IconButton onClick={() => handleOpenEditModal(rollOut)}>
                              <Edit sx={{ color: "#5882E6" }} />
                            </IconButton>

                            <IconButton onClick={() => handleDelete(rollOut)}>
                              <Delete sx={{ color: "#DF6465" }} />
                            </IconButton>
                            <IconButton onClick={() => handleCopy(rollOut)}>
                              <CopyAll sx={{ color: "#075a6a" }} />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                sx={{
                  position: "sticky",
                  bottom: 0,
                  overflowX: "hidden",
                  padding: "0 0.5rem",
                  background: "#f5f3f3",
                  borderTop: "1px solid #e0e0e0",
                  boxShadow: "0px -2px 10px rgba, 0, 0, 0.1)",
                  borderRadius: "0 0 8px 8px",
                }}
                count={rollOutData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
        }
      </div>

      <ModalComponent
        open={openRolloutDetails}
        handleClose={handleCloseRolloutDetails}
        headerText={'Rollout Details'}
        showSubmitButton={false}
      >
        {
          loading ?
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
            :
            <>
              <TableContainer
                sx={{
                  maxHeight: "55vh",
                  overflowX: "hidden",
                  overflowY: "auto",
                  borderRadius: "8px 8px 0 0",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                }}>

                <Table>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 99,
                      "& th": {
                        border: "1px solid #e0e0e0",
                        background: "#F5F3F3",
                        fontWeight: "500",
                        color: "#333",
                      },
                      boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <TableRow>
                      <TableCell align="center">Device</TableCell>
                      <TableCell align="center">Rollout</TableCell>
                      <TableCell align="center">Detail Status	</TableCell>
                      <TableCell align="center">Update Logs</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      "& td": {
                        border: "1px solid #e0e0e0",
                        color: "#555",
                      },
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: "#fafafa",
                      },

                    }}
                  >
                    {rolloutDetails.list.map((item) => (
                      <TableRow key={item._id}>
                        <TableCell align="center">{item.device}</TableCell>
                        <TableCell align="center">{item.rollout}</TableCell>
                        <TableCell align="center" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                          <Typography sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}><span>{item.isDownloaded ? 'Yes' : 'No'}</span><span sx={{ fontWeight: "600" }}>Download</span></Typography>
                          <Typography sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}><span>{item.isExtracted ? 'Yes' : 'No'}</span><span sx={{ fontWeight: "600" }}>Extract</span></Typography>
                          <Typography sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}><span>{item.isApplied ? 'Yes' : 'No'}</span><span sx={{ fontWeight: "600" }}>Apply</span></Typography>
                        </TableCell>
                        <TableCell align="center" onClick={() => handleRolloutLogs(item.rollout, item.device)} sx={{ cursor: 'pointer' }}>{<TableRowsIcon />}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                sx={{
                  position: "sticky",
                  bottom: 0,
                  overflow: "hidden",
                  padding: "0 0.5rem",
                  background: "#f5f3f3",
                  borderTop: "1px solid #e0e0e0",
                  boxShadow: "0px -2px 10px rgba, 0, 0, 0.1)",
                  borderRadius: "0 0 8px 8px",
                }}
                count={rolloutDetails.totalCount} // Total number of items
                page={rolloutDetails.currentPage - 1} // MUI pagination is zero-indexed
                onPageChange={handleRolloutDetailsPageChange}
                rowsPerPage={10} // Fixed rows per page, adjust if needed
                rowsPerPageOptions={[]} // Remove option to change rows per page
              />
            </>
        }
      </ModalComponent>

      <ModalComponent open={openRolloutLogs} handleClose={handleCloseRolloutLogs} headerText={'Rollout Logs'} showSubmitButton={false}>
        {
          loading ?
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
            :
            <>
              <TableContainer
                sx={{
                  maxHeight: "55vh",
                  overflowX: "hidden",
                  overflowY: "auto",
                  borderRadius: "8px 8px 0 0",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                }}>
                <Table>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 99,
                      "& th": {
                        border: "1px solid #e0e0e0",
                        background: "#F5F3F3",
                        fontWeight: "500",
                        color: "#333",
                      },
                      boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <TableRow>
                      <TableCell align="center">Timestamp</TableCell>
                      <TableCell align="center">Message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      "& td": {
                        border: "1px solid #e0e0e0",
                        color: "#555",
                      },
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: "#fafafa",
                      },
                    }}
                  >
                    {RolloutLogsData.map((item) => (
                      <TableRow key={item._id}>
                        <TableCell align="center">{new Date(item.createdAt).toLocaleString()}</TableCell>
                        <TableCell align="center">{item.message}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
        }
      </ModalComponent>
    </MainLayout>
  );
};

export default RollOut;
