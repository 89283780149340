import React, { useState } from "react";
import MainLayout from "../../layout/mainLayout";
import SystemStatus from "../../components/MonitorPageComponents/systemStatus";
import KernelLog from "../../components/MonitorPageComponents/kernelLog";
import Process from "../../components/MonitorPageComponents/process";
import RealTimeGraph from "../../components/MonitorPageComponents/realTImeGraph";
import ApplicationLog from "../../components/MonitorPageComponents/applicationLog";

function Monitors() {
  const [activeSettingTab, setSelectedActiveSettingTab] =
    useState("system_status");
  return (
    <MainLayout setActiveTab={setSelectedActiveSettingTab}>
      {activeSettingTab === "system_status" && <SystemStatus />}
      {activeSettingTab === "kernel_logs" && <KernelLog />}
      {activeSettingTab === "application_logs" && <ApplicationLog />}
      {activeSettingTab === "processes" && <Process />}
      {activeSettingTab === "real_time_graph" && <RealTimeGraph/>}
    </MainLayout>
  );
}

export default Monitors;
