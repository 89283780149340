import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, IconButton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthLayout from '../../layout/authLayout';
import ChargnexLogo from '../../components/icons/chargnexLogo';
import ChargnexBackgroundLogo from '../../components/icons/chargnexBackgroundLogo';
import KeyIcon from '@mui/icons-material/VpnKey';
import axios from 'axios';
import { InputAdornment } from '@mui/material';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from 'react-redux';
import { updateCpoEndpoint } from "../../redux/slices/user/cpoSlice";
import { message } from 'antd';

const checkPasswordCriteria = (password) => {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const isValidLength = password.length >= 8;

  return {
    hasUpperCase,
    hasSpecialChar,
    isValidLength,
    isValid: hasUpperCase && hasSpecialChar && isValidLength,
  };
};

const getPasswordStrength = (password) => {
  const criteria = checkPasswordCriteria(password);
  if (!criteria.isValidLength) {
    return "Too Short";
  }
  if (criteria.isValid && criteria.hasUpperCase && criteria.hasSpecialChar) {
    return "Strong";
  }
  if (criteria.isValid) {
    return "Medium";
  }
  return "Weak";
};

const ResetPassword = () => {
  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();

  const checkEmail = state ? state.email : "";
  const path = state ? state.path : "";
  const rememberMeFromLocation = state ? state.rememberMe : false;

  const [email, setEmail] = useState(checkEmail);
  const [passcode, setPasscode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [msg, setmsg] = useState('');
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState('');
  const [showPasswordStrength, setShowPasswordStrength] = useState(false); // To control visibility of the strength indicator

  const [cpoEndpoint, setCpoEndpoint] = useState("cpo");

  // State to handle password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleErrors = () => {
    let newErrors = {};

    // Check password criteria
    const passwordCriteria = checkPasswordCriteria(newPassword);
    if (!passwordCriteria.isValid) {
      newErrors.newPassword = 'Password must be at least 8 characters, include 1 uppercase letter and 1 special character.';
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = 'Confirm password is required';
    } else if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (checkEmail) {
      setEmail(checkEmail);
    }
  }, [checkEmail]);

  const handlePasscodeChange = (event) => {
    setPasscode(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    const password = event.target.value;
    setNewPassword(password);

    // Show the password strength indicator when typing
    setShowPasswordStrength(password.length > 0);

    // Update password strength based on criteria
    setPasswordStrength(getPasswordStrength(password));

    // Clear the error if password criteria are met
    const passwordCriteria = checkPasswordCriteria(password);
    if (passwordCriteria.isValid) {
      setErrors((prevErrors) => ({ ...prevErrors, newPassword: null }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: 'Password must be at least 8 characters, include 1 uppercase letter and 1 special character.',
      }));
    }
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setmsg('');

    if (!handleErrors()) {
      if (errors.newPassword) {
        message.error(errors.newPassword);
      }
      return;
    }

    try {
      const payload = {
        code: passcode,
        email,
        newPassword,
        confirmPassword,
      };

      const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/auth/reset-password`, payload);
      const { user, session, userRole } = response.data;
      const userDetails = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobile: user.phoneNumber,
        category: userRole,
      };

      const cpoDetails = response.data?.settings?.cpo;
      const eMspDetails = response.data?.settings?.eMsp;

      dispatch(updateCpoEndpoint(cpoDetails?.endpoint));

      setCpoEndpoint(cpoDetails?.endpoint);

      if (rememberMeFromLocation) {
        localStorage.setItem('currentUser', user.id);
        localStorage.setItem(`user_${user.id}`, user.id);
        localStorage.setItem(`token_${user.id}`, session.token);
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        localStorage.setItem('cpoDetails', JSON.stringify(cpoDetails));
        localStorage.setItem('eMspDetails', JSON.stringify(eMspDetails));
      } else {
        sessionStorage.setItem('currentUser', user.id);
        sessionStorage.setItem(`user_${user.id}`, user.id);
        sessionStorage.setItem(`token_${user.id}`, session.token);
        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
        sessionStorage.setItem('cpoDetails', JSON.stringify(cpoDetails));
        sessionStorage.setItem('eMspDetails', JSON.stringify(eMspDetails));
      }

      if (userDetails.category === "CPO Admin") {
        const endpoint = cpoDetails?.endpoint;
        window.location.href = `/csms/${endpoint || "cpo"}/home`;
      } else if (userDetails.category === "factory_manager") {
        navigate("/home");
      } else {
        navigate("/home");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setmsg('An error occurred. Please try again.');
      message.error(error?.response?.data?.message);
    }
  };

  const resendCode = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/auth/forgot-password`, { email });
      message.success('Email Sent Successfully');
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  return (
    <AuthLayout>
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} sx={{ justifyContent: "center" }}>
        <Box sx={{ backgroundColor: "#002f69", padding: "40px", position: "relative", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: { md: "19px 0px 0px 19px", xs: "19px 19px 0px 0px" } }}>
          <ChargnexLogo width={undefined} height={undefined} />
          <Typography component="div" sx={{ position: "absolute", bottom: 0 }}>
            <ChargnexBackgroundLogo />
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1, padding: "40px", borderRadius: "19px", backgroundColor: "#ffffff" }}>
          <Typography component="h1" variant="h5" fontWeight="500" fontSize="30px" marginBottom="20px" textAlign="center">
            {path === 'Forgot Password' ? 'Reset Password' : 'Set Password'}
          </Typography>
          <form onSubmit={onSubmit} noValidate>
            <TextField
              fullWidth
              sx={{ marginBottom: "10px" }}
              id="passcode"
              label="Enter Code"
              name="passcode"
              value={passcode}
              onChange={handlePasscodeChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <Typography component={"a"} variant="body2" sx={{ color: "#002f69", cursor: "pointer", textDecoration: "underline", display: "flex", justifyContent: "flex-end", fontFamily: "Poppins" }} onClick={resendCode}>
              Resend Code
            </Typography>

            <Box sx={{ marginBottom: "20px", marginTop: "10px", position: "relative" }}>
              <TextField
                fullWidth
                name="newPassword"
                label="New Password"
                type={showPassword ? "text" : "password"}
                id="newPassword"
                onChange={handleNewPasswordChange}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: { borderRadius: "14px", fontSize: "14px", height: "48px" },
                }}
                InputLabelProps={{ sx: { fontSize: "14px" } }}
              />
              <Box
                sx={{
                  marginBottom: "6px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {showPasswordStrength && (
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        passwordStrength === "Strong"
                          ? "green"
                          : passwordStrength === "Medium"
                            ? "orange"
                            : "red",
                      fontWeight: passwordStrength === "Strong" ? "bold" : "normal",
                      fontSize: "12px",
                    }}
                  >
                    Password Strength: {passwordStrength}
                  </Typography>
                )}
              </Box>

            </Box>

            <Box sx={{ marginBottom: "20px", position: "relative" }}>
              <TextField
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                onChange={handleConfirmPasswordChange}
                value={confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: { borderRadius: "14px", fontSize: "14px", height: "48px" },
                }}
                InputLabelProps={{ sx: { fontSize: "14px" } }}
                error={!!errors.confirmPassword}
              />
              <Typography
                variant="body2"
                sx={{
                  position: "absolute",
                  top: "56px", // Space below TextField
                  color: "red",
                  height: "20px", // Fixed height for helper text
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {errors.confirmPassword || " "}
              </Typography>
            </Box>

            <Button type="submit" fullWidth variant="contained" sx={{ marginTop: "20px", padding: "10px 0", borderRadius: "14px" }}>
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default ResetPassword;
