import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Pusher from "pusher-js";
import { message } from "antd";
import { invokeAPI } from "../../redux/apis/rapid";
import { useSelector } from "react-redux";
import { CircularProgress, Box } from "@mui/material";


function ProcessTab(props) {
  const [processes, setProcesses] = useState([]);
  const selectedChargerFromRedux = useSelector((state) => state.filter.charger);
  const selectedEvseFromRedux = useSelector((state) => state.filter.evse);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setProcesses([]);
  }, [selectedEvseFromRedux === "" && selectedChargerFromRedux === ""]);

  // A helper function to process the incoming chunked data for processes
  const bindWithChunking = (channel, event, callback) => {
    let events = {};
    channel.bind(event, (data) => {
      if (!events.hasOwnProperty(data.id)) {
        events[data.id] = { chunks: [], receivedFinal: false };
      }
      let ev = events[data.id];
      ev.chunks[data.index] = data.chunk;
      if (data.final) ev.receivedFinal = true;

      // When all chunks are received and the final flag is set, process the data
      if (ev.receivedFinal && ev.chunks.length === Object.keys(ev.chunks).length) {
        try {
          // Wrap the chunk with square brackets to make it a valid JSON array
          const validJsonString = `[${ev.chunks.join(",")}]`;

          // Now parse the entire chunk as a JSON array
          const chunkArray = JSON.parse(validJsonString);

          // Extract the 'processes' array from the chunk data
          const processData = chunkArray[0]; // Assuming the first item contains the processes
          if (processData && Array.isArray(processData.processes)) {
            callback(processData.processes); // Pass the 'processes' array to the callback
          }
        } catch (err) {
          console.error("Error parsing process chunk:", err);
        }

        // Clear the stored chunks for the current event id
        delete events[data.id];
      }
    });
  };

  useEffect(() => {
    if (selectedChargerFromRedux) {
      setLoading(true);
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      });

      const channel = pusher.subscribe(selectedChargerFromRedux.serialNumber);


      // Use the bindWithChunking function to handle incoming chunked process data
      bindWithChunking(channel, 'CHUNKED_LIVE_PROCESS_EVENT', (processData) => {
        console.log("Received chunked process data:", processData);
        setProcesses((prevProcesses) => [...prevProcesses, ...processData]);
        setLoading(false);
      });

      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [selectedChargerFromRedux]);

  // Kill process function
  const KillProcess = (pid) => {
    invokeAPI({
      type: "KILL_PROCESS",
      deviceId: selectedChargerFromRedux?.serialNumber,
      pid: pid,
      reset: false,
    })
      .then((response) => {
        message.success("Process Terminated Successfully");
        setProcesses((prevProcesses) => prevProcesses.filter((process) => process.pid !== pid));
      })
      .catch((err) => {
        message.error("Process Termination Failed");
      });
  };

  return (
    <>
      {
        loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box >
        ) : <Typography component={"div"} padding={"1rem"}>
          <Typography component={"div"} sx={{ mt: "1rem" }}>
            <Table
              sx={{ minWidth: 650, textTransform: "capitalize" }}
              aria-label="simple table"
            >
              <TableHead
                sx={{
                  "& td, & th": { border: "1px solid #4C4E641F !important" },
                  background: "#F5F3F3 0% 0% no-repeat padding-box",
                }}
              >
                <TableRow>
                  <TableCell align="center">Sr. No</TableCell>
                  <TableCell align="center">PID</TableCell>
                  <TableCell align="center">Owner</TableCell>
                  <TableCell align="center">Command</TableCell>
                  <TableCell align="center">CPU Usage (%)</TableCell>
                  <TableCell align="center">Memory Usage (%)</TableCell>
                  <TableCell align="center">Kill</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {processes.map((process, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "& td, & th": {
                        border: "1px solid #4C4E641F !important",
                      },
                    }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{process.p}</TableCell>
                    <TableCell align="center">{process.o}</TableCell>
                    <TableCell align="center">{process.c}</TableCell>
                    <TableCell align="center">{process.cu}</TableCell>
                    <TableCell align="center">{process.mu}</TableCell>
                    <TableCell align="center">
                      <Button variant="contained" color="error" onClick={() => KillProcess(process.p)}>
                        Kill
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Typography>
        </Typography>
      }
    </>
  );
}

export default ProcessTab;
