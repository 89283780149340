import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  IconButton,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Autocomplete,
} from "@mui/material";
import { Add, Delete, Edit, Search } from "@mui/icons-material";
import ModalComponent from "../../components/atoms/ModalComponent";
import {
  fetchBaseRates,
  deleteBaseRate,
  updateBaseRate,
  AddBaseRate,
} from "../../redux/apis/cpo";
import { message } from "antd";
import ButtonComponent from "../../components/atoms/button/button";
import axios from "axios";
import { getCountries } from "../../redux/apis/chargers";
import MainLayout from "../../layout/mainLayout";

const CpoBaseRate = () => {
  const [baseRates, setBaseRates] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [currentRate, setCurrentRate] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedRateId, setSelectedRateId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // New state variables for countries and selected country
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentRate((prev) => ({
      ...prev,
      [name]: name === "name" ? value : parseFloat(value),
    }));

    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  // Fetch base rates from the API
  const fetchBaseRatesData = async () => {
    try {
      setIsLoading(true);
      const response = await fetchBaseRates();
      setBaseRates(response.list);
    } catch (error) {
      console.error("Error fetching base rates:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch countries from the API
  const fetchCountries = async () => {
    try {
      const response = await getCountries({
        limit: 1000,
      });
      setCountries(response.list);
    } catch (error) {
      console.error("Failed to fetch countries:", error);
    } finally {
    }
  };

  const handleCountryChange = (event, newCountry) => {
    setSelectedCountry(newCountry);  // Set the selected country
    setCurrentRate((prevFormData) => ({
      ...prevFormData,
      currency: newCountry?.currency
    }));
  };

  useEffect(() => {
    fetchCountries();
    fetchBaseRatesData();
  }, []);

  // Validation check
  const validateForm = () => {
    let newErrors = {};

    if (!currentRate.name) newErrors.name = "Base Rate Name is required.";
    if (currentRate.baseRateKWH === 0 || !currentRate.baseRateKWH)
      newErrors.baseRateKWH = "Base Rate per kWh cannot be 0.";
    if (currentRate.parkingRate === 0 || !currentRate.parkingRate)
      newErrors.parkingRate = "Parking Rate cannot be 0.";


    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // Handle saving the base rate (add or edit)
  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const payload = {
        name: currentRate.name,
        baseRateKWH: currentRate.baseRateKWH,
        parkingRate: currentRate.parkingRate,
        taxRate: currentRate.taxRate,
        discount: currentRate.discount,
        penalty: currentRate.penalty,
        currency: currentRate.currency,
      };

      if (isAdding) {
        await AddBaseRate(payload);
        message.success("Base Rate Added Successfully");
      } else {
        await updateBaseRate(currentRate.id, payload);
        message.success("Base Rate Updated Successfully");
      }
      fetchBaseRatesData();
      handleCloseEditModal();
    } catch (error) {
      message.error(error.response?.data?.message || "Error Saving Base Rate");
      console.error("Error saving base rate:", error);
    }
  };

  const handleOpenEditModal = (rate) => {
    setCurrentRate(rate);
    setIsAdding(false);

    setCurrentRate((prev) => ({
      ...prev,
      currency: rate?.currency,
    }));

    const country = countries.find(
      (c) => c?.currency === rate?.currency
    );
    
    setSelectedCountry(country || null);

    setOpenEditModal(true);
  };

  const handleOpenAddModal = () => {
    setCurrentRate({
      name: "",
      baseRateKWH: 0,
      parkingRate: 0,
      taxRate: 0,
      discount: 0,
      penalty: 0,
      currency: "",
    });
    setIsAdding(true);
    setSelectedCountry(null);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setCurrentRate(null);
    setErrors({});
    setSelectedCountry(null);
  };

  // Handle deletion of base rate
  const handleDeleteBaseRate = async () => {
    setIsLoading(true);
    try {
      await deleteBaseRate(selectedRateId);
      fetchBaseRatesData();
      message.success("Base Rate Deleted Successfully");
    } catch (error) {
      console.error("Error deleting base rate:", error);
    } finally {
      handleCloseDeleteDialog();
      setIsLoading(false);
      setSelectedRateId(null);
    }
  };

  const handleOpenDeleteModal = (rateId) => {
    setSelectedRateId(rateId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedRateId(null);
    setOpenDeleteDialog(false);
  };

  return (
    <MainLayout>
      <Typography
        component={"h1"}
        fontWeight={"500"}
        fontSize={"1.25rem"}
        marginBottom={"0.5rem"}
      >
        Base Rate Details
      </Typography>
      <Typography
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"0rem 0rem 1rem 0rem"}
      >
        <TextField
          id="search"
          variant="outlined"
          hiddenLabel
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
            sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
          }}
          InputLabelProps={{ sx: { fontSize: "14px" } }}
        />
        <ButtonComponent
          label={
            <>
              <Add /> <Typography component={"span"}>Add Base Rate</Typography>
            </>
          }
          width={"200px"}
          height={"40px"}
          onClick={handleOpenAddModal}
        />
      </Typography>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="55vh">
          <CircularProgress />
        </Box>
      ) : (
        <Grid xs={12} sx={{ marginTop: "1rem" }} gap={2}>
          <TableContainer
            sx={{
              maxHeight: "55vh",
              overflow: "auto",
              borderRadius: "8px 8px 0 0",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid #e0e0e0",
              backgroundColor: "#ffffff",
            }}
          >
            <Table>
              <TableHead
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 99,
                  "& th": {
                    border: "1px solid #e0e0e0",
                    background: "#F5F3F3",
                    fontWeight: "500",
                    color: "#333",
                  },
                  boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <TableRow>
                  <TableCell align="center">Base Rate Name</TableCell>
                  <TableCell align="center">Base Rate per kWh</TableCell>
                  <TableCell align="center">Parking Rate per Hour</TableCell>
                  <TableCell align="center">Tax Rate %</TableCell>
                  <TableCell align="center">Discount %</TableCell>
                  <TableCell align="center">Penalty</TableCell>
                  <TableCell align="center">Currency</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& td": {
                    border: "1px solid #e0e0e0",
                    color: "#555",
                  },
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#fafafa",
                  },
                  "& tr:hover": {
                    backgroundColor: "#f1f1f1",
                  },
                }}
              >
                {baseRates.map((rate) => (
                  <TableRow key={rate.id}>
                    <TableCell align="center">{rate.name}</TableCell>
                    <TableCell align="center">{rate.baseRateKWH}</TableCell>
                    <TableCell align="center">{rate.parkingRate}</TableCell>
                    <TableCell align="center">{rate.taxRate}</TableCell>
                    <TableCell align="center">{rate.discount}</TableCell>
                    <TableCell align="center">{rate.penalty}</TableCell>
                    <TableCell align="center">{rate.currency}</TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => handleOpenEditModal(rate)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleOpenDeleteModal(rate.id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            sx={{
                position: "sticky",
                bottom: 0,
                overflowX: "hidden",
                padding: "0 0.5rem",
                background: "#f5f3f3",
                borderTop: "1px solid #e0e0e0",
                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "0 0 8px 8px",
              }}
            count={baseRates.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      )}

      {/* Add/Edit Modal */}
      {openEditModal && (
        <ModalComponent
          open={openEditModal}
          handleClose={handleCloseEditModal}
          headerText={isAdding ? "Add Base Rate" : "Edit Base Rate"}
          handleSubmit={handleSave}
          submitButtonLabel={isAdding ? "Add" : "Save"}
        >
          <Box display="flex" flexDirection="column" gap={2} sx={{ mt: 2 }}>
            <TextField
              label="Base Rate Name*"
              variant="outlined"
              value={currentRate.name}
              name="name"
              onChange={handleInputChange}
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              label="Base Rate per kWh*"
              variant="outlined"
              value={currentRate.baseRateKWH}
              name="baseRateKWH"
              type="number"
              onChange={handleInputChange}
              error={!!errors.baseRateKWH}
              helperText={errors.baseRateKWH}
            />
            <TextField
              label="Parking Rate per Hour*"
              variant="outlined"
              value={currentRate.parkingRate}
              name="parkingRate"
              type="number"
              onChange={handleInputChange}
              error={!!errors.parkingRate}
              helperText={errors.parkingRate}
            />
            <TextField
              label="Tax Rate (%)"
              variant="outlined"
              value={currentRate.taxRate}
              name="taxRate"
              type="number"
              onChange={handleInputChange}
              error={!!errors.taxRate}
              helperText={errors.taxRate}
            />
            <TextField
              label="Discount (%)"
              variant="outlined"
              value={currentRate.discount}
              name="discount"
              type="number"
              onChange={handleInputChange}
              error={!!errors.discount}
              helperText={errors.discount}
            />
            <TextField
              label="Penalty"
              variant="outlined"
              value={currentRate.penalty}
              name="penalty"
              type="number"
              onChange={handleInputChange}
              error={!!errors.penalty}
              helperText={errors.penalty}
            />
            <Autocomplete
              options={countries}
              getOptionLabel={(option) => {
                return `${option?.name} (${option?.currency})`; // Concatenate country name and currency code
              }}
              value={selectedCountry} // Bind the selected country here
              onChange={handleCountryChange} // Call handleCountryChange
              isOptionEqualToValue={(option, value) =>
                option?.currency === value?.currency // Ensure correct comparison to maintain selection
              }
              renderInput={(params) => (
                <TextField {...params} label="Currency" variant="outlined" />
              )}
            />


          </Box>
        </ModalComponent>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Base Rate?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteBaseRate} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayout>
  );
};

export default CpoBaseRate;
